import React from "react";
import { Grid, Link } from "@mui/material";
import { _t } from "../../store/translate.js";
import { connect } from "react-redux";

function Footer(props) {
	const getPrivacyPage = () => {
		switch (props.vendor.postfix) {
			case "p&m": {
				return (
					<Link href="https://pfeiffer-may.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			case "ivt": {
				return (
					<Link href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			case "kermi": {
				return (
					<Link href="https://www.kermi.de/unternehmen/nutzungsbedingungen-datenschutz/" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			case "purmo": {
				return (
					<Link href="https://www.purmo.com/de-de/datenschutzbestimmungen" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			case "cosmo": {
				return (
					<Link href="https://www.cosmo-info.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			case "v&n": {
				return (
					<Link href="https://www.vogelundnoot.com/at/unternehmen/datenschutz.htm" target="_blank" rel="noopener noreferrer">
						{_t("Datenschutz")}
					</Link>
				);
			}
			default: {
				return <Link href="/privacy">{_t("Datenschutz")}</Link>;
			}
		}
	};

	const getTermsOfUsePage = () => {
		switch (props.vendor.postfix) {
			case "kermi": {
				return (
					<Link href="https://www.kermi.de/unternehmen/nutzungsbedingungen-datenschutz/" target="_blank" rel="noopener noreferrer">
						{_t("Benutzerbedingungen")}
					</Link>
				);
			}
			default: {
				return <Link href="/termsOfUse">{_t("Benutzerbedingungen")}</Link>;
			}
		}
	};
	const getImprint = () => {
		switch (props.vendor.postfix) {
			case "cosmo": {
				return (
					<Link href="https://www.cosmo-info.de/impressum" target="_blank" rel="noopener noreferrer">
						{_t("Impressum")}
					</Link>
				);
			}
			case "purmo": {
				return (
					<Link href="https://www.purmo.com/de-de/datenschutzbestimmungen/impressum" target="_blank" rel="noopener noreferrer">
						{_t("Impressum")}
					</Link>
				);
			}
			case "v&n": {
				return (
					<Link href="https://www.vogelundnoot.com/at/kontakt/impressum.htm" target="_blank" rel="noopener noreferrer">
						{_t("Impressum")}
					</Link>
				);
			}
			default: {
				<Link href="/imprint">{_t("Impressum")}</Link>;
			}
		}
	};

	return (
		<Grid container item xs={12} component="footer" sx={{ fontSize: "1rem", background: "#fafafa", padding: "5px 5px 10px 20px" }} direction="row" spacing={2}>
			<Grid item>{getImprint()}</Grid>
			<Grid item>{getTermsOfUsePage()}</Grid>
			<Grid item>{getPrivacyPage()}</Grid>
			{props.vendor.postfix === "viega" && (
				<Grid item>
					<Link
						id="cookie-settings"
						href="#"
						onClick={() => {
							try {
								// eslint-disable-next-line no-eval
								eval("UC_UI.showSecondLayer();");
							} catch (error) {
								console.error(error?.message);
							}
						}}
					>
						{_t("Cookie-Einstellungen")}
					</Link>
				</Grid>
			)}
		</Grid>
	);
}

const mapStateToProps = (state) => {
	return {
		vendor: state.vendor,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Footer);
