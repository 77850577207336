import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import Cookies from "js-cookie";

import {
	Button,
	Grid,
	Typography,
	IconButton,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Link,
	CircularProgress,
	Tooltip,
	MenuItem,
	Menu,
	Divider,
	Accordion,
} from "@mui/material";
import { AddCircle, Assignment, Delete, DragIndicator, ExpandMore, FileCopy, MoreVert, Print, GetApp, Settings, UnfoldLess } from "@mui/icons-material";

import { AccordionStyle, ButtonWithIconStyles, ProjectStyles } from "./projectStyle.js";
import Layout from "../../layout/layout.js";
import { GridPadding } from "../../mainStyle.js";
import {
	getFloors,
	createFloor,
	editFloor,
	duplicateFloor,
	deleteFloor,
	getFlats,
	createFlat,
	calculateMaterial,
	getCalculatedMaterials,
	createRoom,
	getProjectPDF,
	moveFloor,
	moveRoom,
	moveFlat,
	getWebPro,
} from "./projectController.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { reorderLevel, selectInput, timeout } from "../../store/gloablFunctions.js";
import { getBySystemPipeVendor, getProjectSettings, getSortedBestandteile } from "../projectSettings/projectSettingsController.js";
import { Format, _t } from "../../store/translate.js";
import { Rooms, getRoomsWithExtras, getErrorForDesiredPower, getInsulationOptions } from "./rooms.js";
import { Flat } from "./flat.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function Project(props) {
	const dispatch = useDispatch();
	const { id: projectId } = useParams();
	const isPackagingQuantity = true;
	const [project, setProject] = React.useState({});
	const [floors, setFloors] = React.useState([]);

	const deleteArrayElementById = (array, id) => {
		return array.filter((f) => {
			return f._id !== id;
		});
	};

	/* Floors */
	const getStateFloors = () => {
		var newFloors = [];
		floors.forEach((newFloor) => {
			newFloors.push(newFloor);
		});
		return newFloors;
	};
	const findFloor = (newFloors, floorId) => {
		return newFloors.find((f) => f._id === floorId);
	};
	const setFloors_byInput = async (newProject) => {
		var newFloors = await getFloors(projectId);
		if (newFloors.length > 0) {
			var flats = await getFlats(newFloors[0]._id);
			newFloors[0].expanded = true;
			newFloors[0].flats = flats;
			if (flats.length > 0) {
				var rooms = await getRoomsWithExtras(flats[0]._id, newProject);
				newFloors[0].flats[0].expanded = true;
				newFloors[0].flats[0].rooms = rooms;
			}
		}
		setFloors(newFloors);
	};

	const handleExpandChange_Floor = async (event, floorId) => {
		if (!event.target.closest("button") && event.target.nodeName !== "INPUT" && !event.target.classList.contains("noExpand")) {
			var newFloors = getStateFloors();
			var foundFloor = findFloor(newFloors, floorId);

			if (!foundFloor.flats) {
				setLightLoading(true);
				var flats = await getFlats(floorId);
				foundFloor.flats = flats;
				setLightLoading(false);
			}
			foundFloor.expanded = !foundFloor.expanded;
			setFloors(newFloors);
		}
	};
	const handleClick_AddFloor = async () => {
		var level = 0;
		if (floors.length > 0) {
			// if (before) {
			level = parseInt(floors[0].level) + 1; //* parseInt(floors[0].count);
			// } else {
			// 	level = parseInt(floors[floors.length - 1].level) - 1; //* parseInt(floors[floors.length - 1].count);
			// }
		}
		var floor = await createFloor({ name: _t("Unbenannt"), level: level, count: 1, project: projectId });
		var newFloors = getStateFloors();
		floor.expanded = true;
		// if (before) {
		newFloors.unshift(floor);
		// } else {
		// newFloors.push(floor);
		// }

		setFloors(newFloors);
		changeCalculatedMaterialsState();
		window.scrollTo(0, 0);
	};
	const handleClick_DuplicateFloor = async (floorId) => {
		setLoading(true);
		var request = await duplicateFloor(floorId);
		if (request) {
			window.location.reload();
		}
	};
	const handleEdit_Floor = async (attribute, value, floor) => {
		if ((attribute === "floorCount" && value !== "" && value >= 0) || attribute === "floorName") {
			var newFloors = getStateFloors();
			var foundFloor = findFloor(newFloors, floor._id);
			if (attribute === "floorCount") {
				foundFloor.count = value;
			}
			if (attribute === "floorName") {
				foundFloor.name = value;
			}
			setFloors(newFloors);
			debounce_Floor(foundFloor);
		}
	};
	const handleToggle_CollapsAll = async () => {
		setLightLoading(true);
		var newFloors = getStateFloors();
		newFloors.forEach((floor) => {
			floor.expanded = false;
			if (floor?.flats) {
				floor.flats.forEach((flat) => {
					flat.expanded = false;
				});
			}
		});
		setFloors(newFloors);
		setLightLoading(false);
	};
	// const handleToggle_ExpandFlats = async (floorId) => {
	// 	setLightLoading(true);
	// 	var newFloors = getStateFloors();
	// 	var foundFloor = findFloor(newFloors, floorId);
	// 	foundFloor.flatsExpanded = !foundFloor.flatsExpanded;

	// 	await Promise.all(
	// 		foundFloor.flats.map(async (flat) => {
	// 			flat.expanded = foundFloor.flatsExpanded;
	// 			if (!flat.rooms) {
	// 				var rooms = await getRoomsWithExtras(flat._id, project);
	// 				flat.rooms = rooms;
	// 			}
	// 		})
	// 	);
	// 	setFloors(newFloors);
	// 	setLightLoading(false);
	// };
	const debounce_Floor = useCallback(
		debounce(async (foundFloor) => {
			foundFloor.menu_anchorEl = null;
			await editFloor(foundFloor._id, foundFloor);
			changeCalculatedMaterialsState();
		}, 1000),
		[]
	);
	const NewFloorButton = () => {
		return (
			// <Button
			// 	sx={ButtonWithIconStyles}
			// 	variant="contained"
			// 	color="primary"
			// 	onClick={handleClick_AddFloor}
			// >
			// 	<AddCircle />
			// 	<Typography variant="body1">
			// 		{_t("Geschoss")}
			// 		{/* Appartamento */}
			// 	</Typography>
			// </Button>
			<Tooltip title={_t("Neues Geschoss")}>
				<IconButton color="primary" onClick={handleClick_AddFloor} size="large">
					<AddCircle size="small" />
				</IconButton>
			</Tooltip>
		);
	};

	/* Flats */
	const findFlat = (newFloors, floorId, flatId) => {
		var foundFloor = findFloor(newFloors, floorId);
		var foundFlat = foundFloor.flats.find((f) => f._id === flatId);
		return foundFlat;
	};
	const handleClick_AddFlat = async (floorId) => {
		var newFloors = getStateFloors();
		var foundFloor = findFloor(newFloors, floorId);
		if (!foundFloor?.flats?.length) {
			foundFloor.flats = [];
		}
		var level = foundFloor.flats[0]?.level + 1 || 1;
		var flat = await createFlat({ name: _t("Wohnung") + " " + level, level: level, count: 1, floor: floorId, project: projectId });
		flat.expanded = true;
		foundFloor.flats.unshift(flat);
		setFloors(newFloors);
		changeCalculatedMaterialsState();
	};
	const handleClick_toggleMenu = (currentTarget, floorId) => {
		var newFloors = getStateFloors();
		var foundFloor = findFloor(newFloors, floorId);
		foundFloor.menu_anchorEl = currentTarget;
		setFloors(newFloors);
	};
	const NewFlatButton = (props) => {
		return (
			// <Button
			// 	sx={ButtonWithIconStyles}
			// 	variant="contained"
			// 	color="primary"
			// 	onClick={(e) => {
			// 		handleClick_AddFlat(props.floor._id);
			// 	}}
			// >
			// 	<AddCircle size="small" />
			// 	<Typography variant="body1">{_t("Wohnung")}</Typography>
			// </Button>
			<Tooltip title={_t("Neue Wohnung")}>
				<IconButton
					color="primary"
					onClick={(e) => {
						handleClick_AddFlat(props.floor._id);
					}}
					style={{ marginLeft: "17px" }}
					size="large"
				>
					<AddCircle />
				</IconButton>
			</Tooltip>
		);
	};

	/* Rooms */
	const findRoom = (newFloors, floorId, flatId, roomId) => {
		var foundFloor = findFloor(newFloors, floorId);
		if (foundFloor.flats) {
			var foundFlat = foundFloor.flats.find((f) => f._id === flatId);
			if (foundFlat.rooms) {
				var foundRoom = foundFlat.rooms.find((r) => r._id === roomId);
				return foundRoom;
			}
		}
		return null;
	};
	/* Room ThermoStates*/
	const [roomThermostates, setRoomThermostates] = React.useState({});
	const getRoomThermostates = (newProject, new_json_data) => {
		var thermostates = [];
		if (newProject.heat_system.control_system) {
			var thermostatArray = new_json_data["FBHSystemGruppen"][newProject.heat_system.group]["Raumthermostate"];
			var distributorSubGroups = new_json_data["FBHSystemGruppen"][newProject.heat_system.group]["Einzelraumregelung"][newProject.heat_system.control_system].bestandteile;
			var bestandteile = {};
			thermostatArray.forEach((t) => {
				if (!!distributorSubGroups[t]) {
					bestandteile[t] = distributorSubGroups[t];
				}
			});
			var thermostatesWithGroups = getSortedBestandteile(new_json_data, bestandteile, false);
			if (Object.keys(thermostatesWithGroups).length === 1) {
				var mainGroup = Object.keys(thermostatesWithGroups)[0];
				var defaultSubGroup = newProject.heat_system.control_system_sub_groups[mainGroup];
				var thermostatesSubGroups = thermostatesWithGroups[mainGroup];
				var defaultKey = thermostatesSubGroups[defaultSubGroup].key;

				Object.entries(thermostatesSubGroups).forEach((entry) => {
					const [key, obj] = entry;
					if (key !== "selected") {
						thermostates.push({
							key: obj.key === defaultKey ? "default" : obj.key,
							name: obj.name,
						});
					}
				});
			}
		}
		return thermostates;
	};
	/* Room AreaMargins */
	const [roomAreaMargins, setRoomAreaMargins] = React.useState({});
	const getRoomAreaMargins = async (newProject) => {
		var dataToSubmit = {
			system_group: newProject.heat_system.group,
			system_name: newProject.heat_system.name,
			pipe: newProject.heat_system.pipe,
			vendor: props.vendor._id,
			json_data: newProject.json_data._id,
			language: Cookies.get("language"),
		};
		var areaMargins = await getBySystemPipeVendor(dataToSubmit);
		areaMargins.unshift({ _id: "autoMainMargin", name: "Automatisch" });
		return areaMargins;
	};
	/* Room AddModal */
	const ROOM_TYPES = [
		{ value: "bath", name: _t("Bad"), count: 0 },
		{ value: "kitchen", name: _t("Küche"), count: 0 },
		{ value: "bedroom", name: _t("Schlafzimmer"), count: 0 },
		{ value: "livingRoom", name: _t("Wohnzimmer"), count: 0 },
		{ value: "childrenRoom", name: _t("Kinderzimmer"), count: 0 },
		{ value: "storeroom", name: _t("Abstellraum"), count: 0 },
		{ value: "shower", name: _t("Dusche"), count: 0 },
		{ value: "diningRoom", name: _t("Esszimmer"), count: 0 },
		{ value: "guestRoom", name: _t("Gästezimmer"), count: 0 },
		{ value: "stairway", name: _t("Treppenhaus"), count: 0 },
		{ value: "toilet", name: _t("WC"), count: 0 },
		{ value: "hallway", name: _t("Flur"), count: 0 },
		{ value: "other", name: _t("Sonstiges"), count: 0 },
	];
	const [selectedRoomTypes, setSelectedRoomTypes] = React.useState(ROOM_TYPES);
	const [currentRoomData, setCurrentRoomData] = React.useState({});
	const [createRoomModal, setCreateRoomModal] = React.useState(false);
	const RoomModal = (
		<Grid container spacing={1} sx={GridPadding}>
			{selectedRoomTypes.map((roomtype) => (
				<Grid item container key={roomtype.value} direction="row" alignItems="center" spacing={1}>
					<Grid item>
						<TextField
							variant="standard"
							type="number"
							value={roomtype.count}
							inputProps={{ min: 0, style: { fontWeight: roomtype.count > 0 ? "bold" : "normal" } }}
							sx={{ width: "40px" }}
							onClick={selectInput}
							onWheel={(event) => {
								event.target.blur();
							}}
							onChange={(event) => {
								handleEdit_roomType(roomtype.value, event.target.value);
							}}
						/>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="inherit"
							fullWidth
							onClick={(event) => {
								handleEdit_roomType(roomtype.value);
							}}
						>
							<AddCircle color="primary" />
							<Typography variant="body1" style={{ paddingLeft: "5px", width: "200px" }} align="left">
								{roomtype.name}
							</Typography>
						</Button>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
	const getStateSelectedRoomTypes = () => {
		var newSelectedRoomTypes = [];
		selectedRoomTypes.forEach((selectedRoomType) => {
			newSelectedRoomTypes.push(selectedRoomType);
		});
		return newSelectedRoomTypes;
	};
	const handleEdit_roomType = (roomtype, count) => {
		var newSelectedRoomTypes = getStateSelectedRoomTypes();
		var foundRoomType = newSelectedRoomTypes.find((r) => r.value === roomtype);
		foundRoomType.count = count ? count : parseInt(foundRoomType.count) + 1;
		setSelectedRoomTypes(newSelectedRoomTypes);
	};
	const handleClick_OpenAddRoomsModal = async (floorId, flatId) => {
		setCurrentRoomData({ floorId: floorId, flatId: flatId });
		setCreateRoomModal(true);
	};
	const handleClick_AddRoomsOnConfirmation = async () => {
		setLightLoading(true);
		var { floorId, flatId } = currentRoomData;
		var level = 1;
		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		if (foundFlat.rooms && foundFlat.rooms.length > 0) {
			level = parseInt(foundFlat.rooms[foundFlat.rooms.length - 1].level) - 1;
		} else {
			foundFlat.rooms = [];
		}
		var insulation_options = await getInsulationOptions({ insulation_case: "u" }, project);
		var newRooms = [];
		// selectedRoomTypes.forEach((selectedRoomType) => {
		for (let i = 0; i < selectedRoomTypes.length; i++) {
			while (selectedRoomTypes[i].count > 0) {
				newRooms.push({
					count: 1,
					name: selectedRoomTypes[i].name,
					level: level,
					type: selectedRoomTypes[i].value,
					insulation_option_index: 0,
					floor: floorId,
					flat: flatId,
					project: projectId,
				});
				level--;
				selectedRoomTypes[i].count--;
			}
		}
		var rooms = await createRoom(newRooms);
		for (const room of rooms) {
			//transform objects to ids
			room.flat = room.flat._id;
			room.floor = room.floor._id;
			if (room.calculations && room.calculations.error && room.calculations.error.title) {
				room.errors["desired_power"] = getErrorForDesiredPower(room.calculations);
			}
			room.insulation_options = insulation_options;
			foundFlat.rooms.push(room);
		}

		setFloors(newFloors);
		changeCalculatedMaterialsState();
		setLightLoading(false);
	};

	/* Material */
	const [calculatedMaterials, setCalculatedMaterials] = React.useState({});
	const [materialCalculatedModal, setMaterialCalculatedModal] = React.useState(false);
	const handleClick_CreateMaterials = async () => {
		if (Object.keys(calculatedMaterials).length > 0) {
			window.open("/material/" + projectId, "_blank");
		} else {
			setLoading(true);
			await calculateMaterial(projectId);
			var currentCalculatedMaterials = await pollingForCalculatedMaterials();
			if (currentCalculatedMaterials) {
				setCalculatedMaterials(currentCalculatedMaterials);
				setMaterialCalculatedModal(true);
			}
			setLoading(false);
		}
	};
	const handleClick_PrintResults = async (pdfWindow) => {
		setLightLoading(true);
		var request = await getProjectPDF(projectId);
		pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(request) + "'></iframe>");
		setLightLoading(false);
	};
	const handleClick_ExportWebpro = () => {
		getWebPro(projectId);
	};
	const pollingForCalculatedMaterials = async () => {
		var count = 0;
		while (true) {
			var request = await getCalculatedMaterials(projectId, isPackagingQuantity);
			if (Object.keys(request).length > 0) {
				return request;
			}
			count++;
			if (count > 10) {
				var error = "request timeout getCalculatedMaterials";
				dispatch({ type: "error_modal", payload: { show: true, title: "Timeout", text: error } });
				return null;
			}
			await timeout(500 * count);
		}
	};
	const changeCalculatedMaterialsState = () => {
		setCalculatedMaterials({});
	};

	/* Delete Modal */
	const [deleteModal, setDeleteModal] = React.useState(false);
	const [toDeleteFloor, setToDeleteFloor] = React.useState({});
	const handleClick_OpenDeleteModal = async (floor) => {
		setToDeleteFloor(floor);
		setDeleteModal(true);
	};
	const handleClick_ConfirmDeleteFloor = async () => {
		setLoading(true);
		var request = await deleteFloor(toDeleteFloor._id);
		if (request) {
			window.location.reload();
		}
	};

	/* Drag & Drop */
	const moveFloorTo = async (startIndex, endIndex, floorId) => {
		var newFloors = getStateFloors();
		var toIndex = newFloors[endIndex].level;

		//reorder
		const [removed] = newFloors.splice(startIndex, 1);
		newFloors.splice(endIndex, 0, removed);
		reorderLevel(newFloors);
		setFloors(newFloors);

		await moveFloor(floorId, toIndex);
	};
	const moveFlatTo = async (startIndex, endIndex, floorId, flatId) => {
		var newFloors = getStateFloors();
		var foundFloor = findFloor(newFloors, floorId);
		var flats = foundFloor.flats;
		var toIndex = flats[endIndex].level;

		//reorder
		const [removed] = flats.splice(startIndex, 1);
		flats.splice(endIndex, 0, removed);
		reorderLevel(flats);
		setFloors(newFloors);

		await moveFlat(flatId, floorId, toIndex);
	};
	const moveRoomTo = async (startIndex, endIndex, floorId, flatId, roomId) => {
		var newFloors = getStateFloors();
		var foundFlat = findFlat(newFloors, floorId, flatId);
		var rooms = foundFlat.rooms;
		var toIndex = rooms[endIndex].level;

		//reorder
		const [removed] = rooms.splice(startIndex, 1);
		rooms.splice(endIndex, 0, removed);
		reorderLevel(rooms, true);
		setFloors(newFloors);

		await moveRoom(roomId, flatId, toIndex);
	};
	const getItemStyle = (isDragging, draggableStyle) => ({
		// styles we need to apply on draggables
		...draggableStyle,

		...(isDragging && {
			background: "#efefef",
		}),
	});
	const onDragEnd = async (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		if (result.source.index !== result.destination.index) {
			var ids = result.draggableId.split(",");
			// [0] => floorId , [1] => flatId , [2] => roomId
			if (ids.length === 3) {
				await moveRoomTo(result.source.index, result.destination.index, ids[0], ids[1], ids[2]);
				setCalculatedMaterials({});
			} else if (ids.length === 2) {
				await moveFlatTo(result.source.index, result.destination.index, ids[0], ids[1]);
			} else if (ids.length === 1) {
				await moveFloorTo(result.source.index, result.destination.index, ids[0]);
			}
		}
	};

	/* Init project */
	const [loading, setLoading] = React.useState(true);
	const [lightLoading, setLightLoading] = React.useState(false);
	React.useEffect(() => {
		async function fetchData() {
			if (projectId) {
				var newProject = await getProjectSettings(projectId);
				setProject(newProject);
				var new_json_data = JSON.parse(newProject.json_data.data);
				var newRoomThermostates = getRoomThermostates(newProject, new_json_data);
				setRoomThermostates(newRoomThermostates);
				var newRoomAreaMargins = await getRoomAreaMargins(newProject);
				setRoomAreaMargins(newRoomAreaMargins);
				await setFloors_byInput(newProject);
				var currentCalculatedMaterials = await getCalculatedMaterials(projectId, isPackagingQuantity);
				setCalculatedMaterials(currentCalculatedMaterials);
				setLoading(false);
			}
			return () => {};
		}
		fetchData();
	}, []);

	/* #region  fixed header when scrolling */

	var header;
	var rootContent;

	window.onresize = function () {
		rootContent.style.marginTop = `${header.offsetHeight}px`;
	};

	useEffect(() => {
		if (!loading) {
			header = document.getElementById("myHeader");
			rootContent = document.getElementById("myRoot");
			rootContent.style.marginTop = `${header.offsetHeight}px`;
		}
	});

	/* #endregion */

	return (
		<Layout>
			{loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}
			{lightLoading && <CircularProgress style={{ position: "fixed", left: "50%", top: "50%", zIndex: 1, transform: "translate(-50%, -50%)" }} />}
			{deleteModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setDeleteModal}
					title={Format(_t("%1 löschen?"), [toDeleteFloor.name])}
					acceptbutton={handleClick_ConfirmDeleteFloor}
				/>
			)}
			{materialCalculatedModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={materialCalculatedModal}
					closed={setMaterialCalculatedModal}
					title={_t("Materialliste erstellt!")}
					navigateTo={"/material/" + projectId}
				/>
			)}
			{createRoomModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={() => {
						setSelectedRoomTypes(ROOM_TYPES);
						setCreateRoomModal(false);
					}}
					title={_t("Räume erstellen:")}
					acceptbutton={handleClick_AddRoomsOnConfirmation}
					children={RoomModal}
				/>
			)}
			{!loading && (
				<Grid item component={ProjectStyles} sx={{ padding: "10px 0px 15px" }} xs={12}>
					{/* TODO paddingRight 5px?  */}
					<Grid
						item
						container
						justifyContent="space-between"
						sx={{
							...GridPadding,
							background: "white",
							paddingRight: "40px !important",
							position: "fixed",
							zIndex: 2,
							marginTop: "-20px",
							paddingTop: "25px !important",
						}}
						xs={12}
						// style={{ paddingRight: "5px" }}
						id="myHeader"
					>
						<Grid item xs={12} xl={7}>
							<Grid item container alignItems="center" xs={12}>
								<Grid item xs={4} md={2} lg={1} xl={2} container alignItems="center">
									<Grid item xs={4}>
										<Tooltip title={_t("Alles Verbergen")}>
											<IconButton color="primary" onClick={handleToggle_CollapsAll} size="large">
												<UnfoldLess size="small" />
											</IconButton>
										</Tooltip>
									</Grid>
									<Grid item xs={4}>
										<Tooltip title={_t("Einstellungen")}>
											<Link href={"/projectsettings/" + projectId}>
												<IconButton color="primary" size="large">
													<Settings size="small" />
												</IconButton>
											</Link>
										</Tooltip>
									</Grid>
									<Grid item xs={4}>
										<NewFloorButton />
									</Grid>
								</Grid>
								<Grid item xs={8} md={10} xl={10}>
									<Grid item xs={12}>
										<Typography component="h5" variant="h5" noWrap>
											{project.name}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<Button
										sx={ButtonWithIconStyles}
										variant="contained"
										color={Object.keys(calculatedMaterials).length > 0 ? "primary" : "inherit"}
										onClick={(e) => {
											handleClick_CreateMaterials();
										}}
									>
										<Assignment />
										<Typography variant="body1">{Object.keys(calculatedMaterials).length > 0 ? _t("Material anzeigen") : _t("Material erstellen")}</Typography>
									</Button>
								</Grid>
								<Grid item>
									<Button
										color="inherit"
										sx={ButtonWithIconStyles}
										variant="contained"
										onClick={(e) => {
											handleClick_PrintResults(window.open("about:blank"));
										}}
									>
										<Print />
										<Typography variant="body1">{_t("Ergebnisse drucken")}</Typography>
									</Button>
								</Grid>
								<Grid item>
									<Button
										color="inherit"
										sx={ButtonWithIconStyles}
										variant="contained"
										onClick={(e) => {
											handleClick_ExportWebpro();
										}}
									>
										<GetApp />
										<Typography variant="body1">{_t("Export Webpro ")}</Typography>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppableFloors" direction="vertical">
							{(droppableProvided) => (
								<Grid id="myRoot" container item xs={12} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
									{floors.map((floor, index) => (
										<Draggable key={floor._id} draggableId={floor._id} index={index}>
											{(provided, snapshot) => {
												return (
													<Accordion
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
														sx={AccordionStyle}
														key={floor._id}
														expanded={!!floor.expanded}
														onChange={(event) => {
															handleExpandChange_Floor(event, floor._id);
														}}
													>
														<AccordionSummary expandIcon={<ExpandMore />}>
															<Grid container item direction="row" justifyContent="space-between" alignItems="center">
																<Grid item>
																	<Grid container item direction="row" alignItems="center" spacing={1}>
																		<Grid item>
																			{floor.count > 1 && (
																				<Typography component="div" variant="body1">
																					({floor.count}×)
																				</Typography>
																			)}
																		</Grid>
																		{floor.expanded && (
																			<Grid item>
																				<NewFlatButton floor={floor} />
																			</Grid>
																		)}
																		<Grid item style={{ paddingRight: "20px" }}>
																			<TextField
																				variant="standard"
																				// label={_t("Etage")}
																				value={floor.name}
																				className={"nakedInput"}
																				// inputProps={{ 'aria-label': 'naked' }}
																				onClick={selectInput}
																				onChange={(event) => {
																					handleEdit_Floor("floorName", event.target.value, floor);
																				}}
																				autoFocus={!!floor.expanded}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item>
																	<Grid container item justifyContent="flex-end">
																		<Grid container justifyContent="flex-end" spacing={2}>
																			<Grid item>
																				<Tooltip title={_t("Mehr anzeigen")}>
																					<IconButton
																						size="small"
																						aria-label="more"
																						aria-controls="long-menu"
																						aria-haspopup="true"
																						onClick={(event) => {
																							event.stopPropagation();
																							handleClick_toggleMenu(event.currentTarget, floor._id);
																						}}
																					>
																						<MoreVert />
																					</IconButton>
																				</Tooltip>
																				<Menu
																					id="long-menu"
																					anchorEl={floor.menu_anchorEl}
																					keepMounted
																					open={Boolean(floor.menu_anchorEl)}
																					onClose={(event) => {
																						event.stopPropagation();
																						handleClick_toggleMenu(null, floor._id);
																					}}
																					sx={{ width: "300px" }}
																				>
																					<MenuItem>
																						<TextField
																							type="number"
																							label={Format(_t(`Multiplikator für %1`), [floor.name])}
																							variant="outlined"
																							value={floor.count}
																							fullWidth
																							inputProps={{ min: 1, style: { textAlign: "right" } }}
																							className={"changeFloorCount"}
																							sx={{ margin: "0 10px" }}
																							onClick={selectInput}
																							onWheel={(event) => {
																								event.target.blur();
																							}}
																							onChange={(event) => {
																								handleEdit_Floor("floorCount", event.target.value, floor);
																							}}
																						/>
																					</MenuItem>
																					<br />
																					<Divider />
																					<MenuItem>
																						<Button
																							startIcon={<FileCopy />}
																							color="primary"
																							onClick={(e) => {
																								handleClick_DuplicateFloor(floor._id);
																							}}
																							style={{ justifyContent: "flex-start" }}
																						>
																							<Typography variant="body1">{_t("Geschoss kopieren")}</Typography>
																						</Button>
																					</MenuItem>
																					<MenuItem>
																						<Button
																							startIcon={<Delete />}
																							color="error"
																							onClick={(e) => {
																								handleClick_OpenDeleteModal(floor);
																							}}
																							style={{ justifyContent: "flex-start" }}
																						>
																							<Typography variant="body1">{_t("Geschoss löschen")}</Typography>
																						</Button>
																					</MenuItem>
																				</Menu>

																				<Tooltip title={_t("Verschieben")} {...provided.dragHandleProps}>
																					<IconButton size="small">
																						<DragIndicator />
																					</IconButton>
																				</Tooltip>
																			</Grid>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails sx={{ background: "#ececec !important" }}>
															<DragDropContext onDragEnd={onDragEnd}>
																<Droppable droppableId="droppableFlats" direction="vertical">
																	{(droppableProvidedFlats) => (
																		<Grid
																			container
																			item
																			xs={12}
																			ref={droppableProvidedFlats.innerRef}
																			{...droppableProvidedFlats.droppableProps}
																		>
																			{floor.flats &&
																				floor.flats.map((flat, index) => (
																					<Flat
																						key={flat._id}
																						vendor={props.vendor}
																						project={project}
																						getStateFloors={getStateFloors}
																						floors={floors}
																						floor={floor}
																						findFloor={findFloor}
																						setFloors={setFloors}
																						flat={flat}
																						findFlat={findFlat}
																						findRoom={findRoom}
																						roomAreaMargins={roomAreaMargins}
																						roomThermostates={roomThermostates}
																						deleteArrayElementById={deleteArrayElementById}
																						setLightLoading={setLightLoading}
																						setCalculatedMaterials={setCalculatedMaterials}
																						changeCalculatedMaterialsState={changeCalculatedMaterialsState}
																						handleClick_OpenAddRoomsModal={handleClick_OpenAddRoomsModal}
																						getItemStyle={getItemStyle}
																						index={index}
																					>
																						<Rooms
																							vendor={props.vendor}
																							project={project}
																							getStateFloors={getStateFloors}
																							floor={floor}
																							setFloors={setFloors}
																							flat={flat}
																							findFlat={findFlat}
																							findRoom={findRoom}
																							roomAreaMargins={roomAreaMargins}
																							roomThermostates={roomThermostates}
																							deleteArrayElementById={deleteArrayElementById}
																							setLightLoading={setLightLoading}
																							setCalculatedMaterials={setCalculatedMaterials}
																							changeCalculatedMaterialsState={changeCalculatedMaterialsState}
																							moveRoomTo={moveRoomTo}
																							getItemStyle={getItemStyle}
																							onDragEnd={onDragEnd}
																						/>
																					</Flat>
																				))}
																			{droppableProvidedFlats.placeholder}
																		</Grid>
																	)}
																</Droppable>
															</DragDropContext>
														</AccordionDetails>
													</Accordion>
												);
											}}
										</Draggable>
									))}
									{droppableProvided.placeholder}
								</Grid>
							)}
						</Droppable>
					</DragDropContext>
				</Grid>
			)}
		</Layout>
	);
}

export default Project;
