import React from "react";
import { Grid, Typography } from "@mui/material";

import { GridPadding } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { _t } from "../../store/translate.js";
import Cookies from "js-cookie";

function TermsOfUse(props) {
	

	const lang = Cookies.get("language");

	const vendorList = {
		buderus_de: (
			<Grid container direction="column" sx={GridPadding}>
				Diese Nutzungsbedingungen gelten für die Nutzung des Buderus Logafloor Planungstools (nachfolgend „Software“) , die Ihnen von der Bosch Thermotechnik GmbH
				Sophienstraße 30-3, 35576 Wetzlar (nachfolgend „wir“, „uns“ „Bosch“) zur Nutzung bereitgestellt wird. <br />
				<h3> 1. Anbieter</h3>
				Anbieter der Software ist die Bosch Thermotechnik GmbH, Sophienstraße 30-32, 35576 Wetzlar mit Sitz der Gesellschaft in Wetzlar, Registergericht: Amtsgericht
				Wetzlar HR B 13, Umsatzsteuer-Identifikationsnummer: DE 811164527
				<br />
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer, Jan Brockmann (Vorsitzender), Thomas Bauer, Dr. Thomas Volz und Dr. Henrik Siegle vertreten.
				<br />
				<br />
				Anbieter der Software ist auch die LINEAR Gesellschaft für konstruktives Design mbH, Im Süsterfeld 20, 52072 Aachen, Registergericht: Amtsgericht Aachen HRB 5701,
				Umsatzsteuer-Identifikationsnummer: DE160367433.
				<br />
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten
				<br />
				<h3>2. Anwendungsbereich</h3>
				Bosch stellt Ihnen die Software ausschließlich aufgrund der vorliegenden Nutzungsbedingungen kostenlos bereit.
				<br />
				<br />
				Mit der Nutzung der Software erklären Sie sich mit den nachfolgenden Bedingungen einverstanden.
				<br />
				<br />
				Die Anbieter ermöglichen Ihnen mit der Software einen Überblick darüber zu erlangen, welche Materialien bei dem Einbau einer Bosch Fußbodenheizung ungefähr benötigt
				werden. Die Software ersetzt dabei keine Fachplanung und berücksichtigt auch nicht individuelle Besonderheiten eines konkreten Bauvorhabens. Die Software von ist
				nur für die Benutzung durch geschulte Fachleute bestimmt; sie ersetzt nicht das Urteil des Fachmanns, sondern ist lediglich als Hilfe für die Konstruktion und
				Berechnung bestimmter Gewerke bestimmt. Die mit der Software automatisch ermittelten Daten können daher nur Anhaltspunkt sein und stellen nicht die Basis für eine
				verbindliche Planung und Materialbestellung dar. Eine unabhängige Prüfung der Rechenergebnisse der Software sowie der Beanspruchung, Sicherheit und Gebrauchseignung
				der mit ihrer Hilfe errechneten Gewerke bleibt weiterhin erforderlich.
				<br />
				<br />
				Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<br />
				<br />
				Die Anbieter behalten sich jederzeit vor, die Software auch wieder abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird jedoch nicht
				vor Ablauf von 4 Wochen gelöscht.
				<br />
				<h3>3. Bereitstellung der Software</h3>
				Um die Software nutzen zu können, müssen Sie online auf diese zugreifen. Sie haben selbst für die Möglichkeit der entsprechenden Datenkommunikation zu sorgen und
				tragen etwaige Kosten der Datenübertragung.
				<br />
				<h3>4. Nutzungsrechte</h3>
				Mit Abschluss dieser Nutzungsbedingungen, werden Ihnen die nachfolgenden Nutzungsrechte für die Software eingeräumt:
				<br />
				<br />
				Bosch gewährt Ihnen das nicht-ausschließliche, nicht übertragbare Recht zur Nutzung der Software im vorbezeichneten Anwendungsbereich auf einem in Ihrem Eigentum
				stehenden bzw. in Ihrer Kontrolle unterliegenden Gerät.
				<br />
				<br />
				Es ist Ihnen nicht gestattet, die Software zu vertreiben oder anderweitig Dritten zu übertragen.
				<br />
				<br />
				Sie sind nicht berechtigt, den Quellcode der Software oder Teile hiervon zu verändern, rückwärts zu entwickeln (reverse engineering), zu dekompilieren, zu
				disassemblieren oder den Quellcode auf andere Weise festzustellen sowie abgeleitete Werke der Software zu erstellen. Die zwingenden, nicht abdingbaren Bestimmungen
				der §§ 69d, 69e UrhG bleiben hiervon jedoch unberührt.
				<br />
				<br />
				Die vorstehenden Bedingungen gelten auch für alle späteren Versionen sowie etwaige Programmergänzungen, soweit diese nicht Gegenstand einer gesonderten Vereinbarung
				sind.
				<br />
				<br />
				Alle übrigen Rechte sind Bosch vorbehalten.
				<br />
				<h3>5. Geistiges Eigentum</h3>
				Die Software ist Eigentum der Anbieter und ist urheberrechtlich oder durch andere Rechte des geistigen Eigentums geschützt. Es ist nicht gestattet, Hinweise und
				Vermerke in der Software zu entfernen, die sich auf Vertraulichkeit, Urheberrechte, Markenrechte, Patentrechte und andere Rechte an geistigem Eigentum beziehen.
				<br />
				<br />
				Verstößt die Software oder der Besitz oder die Nutzung durch Sie gegen das Recht am geistigen Eigentum eines Dritten, ist Bosch alleinverantwortlich für die
				Untersuchung, Verteidigung, Regelung und Erfüllung jeglicher Forderungen, die aus einer Verletzung des Rechts am geistigen Eigentum entstehen.
				<br />
				<h3>6. Mitwirkungspflichten</h3>
				Bei der Nutzung der Software haben Sie die für eine Verwendung notwendige Sorgfaltspflicht einzuhalten und die mit der Software generierten Ergebnisse vor deren
				Verwendung in angemessenem Umfang zu prüfen.
				<br />
				<br />
				Zudem sind Sie verpflichtet, regelmäßig eine Sicherung Ihrer Daten vorzunehmen, um sicherzustellen, dass diese im Falle eines Verlustes wiederhergestellt werden
				können.
				<br />
				<h3>7. Datenschutz</h3>
				Wir verarbeiten personenbezogene Daten in Übereinstimmung mit unseren Datenschutzhinweisen.
				<br />
				<h3>8. Projektdaten</h3>
				Die Software ermöglicht dem Nutzer viele Projekte anzulegen. Diese können virtuell abgespeichert werden. Die Anbieter haben keine Aufbewahrungs- oder
				Archivierungspflicht für diese Projektdaten. Aus diesem Grund wird empfohlen, dass der Anwender die ermittelten Projektdaten bei sich abspeichert. Grundsätzlich
				besteht für die Anbieter die Möglichkeit zum Zugriff auf die hinterlegten Daten. Projektdaten werden nicht an Dritte weitergegeben. Allerdings behalten es sich die
				Anbieter vor eine Abfrage durchzuführen, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen. Dadurch erhalten die Anbieter die Möglichkeit notwendige
				Auswertungen und Statistiken zu erstellen.
				<br />
				<h3>9. Gebühren</h3>
				Die Bereitstellung der Software erfolgt kostenlos.
				<br />
				<h3>10. Gewährleistung und Haftung</h3>
				Bosch übernimmt keine Gewährleistung für Sach- und Rechtsmängel, außer in Fällen, in denen Bosch den jeweiligen Sach- oder Rechtsmangel arglistig verschwiegen hat.
				<br />
				<br />
				Bosch übernimmt keine Haftung für Schäden, die aus der Nutzung der Software resultieren, es sei denn, es handelt sich um grobe Fahrlässigkeit oder Vorsatz. Die
				Haftung nach dem Produkthaftungsgesetz bleibt hiervon unberührt.
				<br />
				<br />
				Die vorstehenden Haftungsbeschränkungen gelten auch im Falle des Verschuldens eines Erfüllungsgehilfen von Bosch sowie für die persönliche Haftung der Mitarbeiter,
				Vertreter und Organe von Bosch.
				<br />
				<h3>11. Schlussbestimmungen</h3>
				Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Diese Plattform soll als Anlaufstelle zur außergerichtlichen Beilegung von
				Streitigkeiten dienen, die vertragliche Verpflichtungen von Online-Kaufverträgen und Online-Dienstverträgen betreffen. Die Plattform ist unter
				http://ec.europa.eu/consumers/odr/ erreichbar.
				<br />
				<br />
				Bosch nimmt nicht an einem außergerichtlichen Streitbeilegungsverfahren vor einer Verbraucher-Schlichtungsstelle teil. Beanstandungen, Beschwerden, Fragen und
				Anmerkungen zur App können unter folgenden Kontaktdaten geltend gemacht werden: E-Mail: info@buderus.de
				<br />
				<br />
				Die Vertragsbeziehung zwischen Bosch und Ihnen unterliegt dem Recht der Bundesrepublik Deutschland. Sind Sie Verbraucher, gilt diese Rechtswahl nur insoweit, als
				nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem Sie Ihren gewöhnlichen Aufenthalt haben, entzogen wird. Die Geltung des UN
				Kaufrechts ist ausgeschlossen.
				<br />
				<br />
				Sind Sie Unternehmer im Sinne von § 14 BGB, juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen, ist ausschließlicher
				Gerichtsstand für alle Rechtsstreitigkeit aus oder im Zusammenhang mit diesen Lizenzbedingungen Stuttgart, Deutschland.
				<br />
				<br />
				Sollte eine Bestimmung dieser Nutzungsbedingungen ungültig oder nicht durchsetzbar sein oder werden, so berührt dies die übrigen Bestimmungen nicht.
				<br />
			</Grid>
		),
		henco_en: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Supplier</h3>
				The software is provided free of charge by
				<br />
				Henco Floor NV
				<br />
				Toekomstlaan 22
				<br />
				2200 Herentals | Belgium
				<br />
				Web: www.henco.be
				<br />
				Email: project@hencofloor.be
				<br />
				Commercial registration number: BE0862367117
				<br />
				VAT ID-No.: BE0443598222
				<br />
				Managing Directors: Wim Verhoeven
				<br />
				<br />
				Web application developed and provided by
				<br />
				LINEAR GmbH
				<br />
				Im Suesterfeld 20
				<br />
				52072 Aachen
				<br />
				GERMANY
				<br />
				Commercial register: Amtsgericht Aachen HRB 5701
				<br />
				VAT-ID No.: DE160367433.
				<br />
				Management board: Manfred Waluga, Javier Castell Codesal and Juergen Frantzen
				<br />
				<br />
				<br />
				<h3> 2. Purpose of the software </h3>
				With this free offer, the suppliers want to give specialist craftsmen and other interested parties the opportunity to get an overview of which materials are
				required for the installation of a Henco Floor underfloor heating system. The software does not contain any specialist planning. It cannot take individual features
				of a building project into consideration. For this reason, the data which is automatically detected by the software can only serve as an indication rather than a
				binding basis for an order. The supplier is therefore not liable for the accuracy and completeness of the data.
				<h3> 3. Personal data </h3>
				This application uses Google-Analytics, a web analysis service from Google Inc. ("Google"). Google-Analytics uses so-called "cookies": text files which are saved on
				your computer and enables analysis of your use of the application. The information created by the cookie about your use of the website (including your IP address)
				is transmitted to a Google server in the USA and saved there. Google will use this information to evaluate your use of the application, to compile reports about
				your activities for the suppliers and to provide further services related to use of the application and the internet. Google may also transmit this information to
				third parties in so far as this is legally required or in so far as third parties process this data on behalf of Google. Google will never link your IP address with
				other data saved by Google. You can prevent installation of cookies using corresponding browser software settings. We would like to point out however, that in this
				case not all the functions of this application will be able to be used to their full extent. Through confirmation of this licence agreement you declare your
				agreement with the processing of the data collected about you by Google in the way described above and for the above-mentioned purpose. In order to use the software
				it is necessary to enter a valid email address and a password of your choice. This data is automatically saved and will not be forwarded to third parties. The
				suppliers will not use the stored email addresses for advertising purposes. However, the suppliers reserve the right to use the stored email addresses to
				communicate important information related to this planning software, such as changes to the conditions of use, extensions to the program or software shutdown.
				<h3> 4. Creation of projects </h3>
				The software enables any number of projects to be created. These can be saved in a virtual environment. The suppliers have no storage or archiving obligations. For
				this reason, users should save the project data in question themselves. It is possible in principle for the suppliers to access the stored data. Project data will
				not be forwarded to third parties. However, the suppliers reserve the right to query the necessity and suitability of the data use. This gives the suppliers the
				possibility of preparing necessary evaluations and statistics.
				<h3> 5. Use of cookies </h3>
				The software uses so-called cookies to be able to make use of the software attractive and permit specific functions. These are small text files which are saved on
				your terminal. Some of the cookies are deleted at the end of the browser session, i.e. once you close your browser. Other cookies remain on your terminal. You can
				adjust your browser settings in such a way that you are informed about cookies being set and can decide individually about whether to accept their use. The
				suppliers cannot guarantee that the software will work perfectly if the use of cookies is not permitted.
				<h3> 6. Data protection </h3>
				You can object to the use, processing and forwarding of your personal data at any time by sending an informal letter by post to the address stipulated in section 1
				of these conditions or by sending an email to the named address of the provider. In accordance with the BDSG (Federal Data Protection Act)” you have a right to free
				access to information about the personal data saved about you as well as possibly a right to correction, disabling or deletion of this data. We will no longer use
				the data concerned upon receipt of your objection. In addition, you have the possibility of deleting your account yourself.
				<h3> 7. Miscellaneous </h3>
				The suppliers retain the right to shut down the software at any time. No guarantee can be given for the permanent availability of the data saved on the accounts.
				The software cannot be used in particular during maintenance work or disruptions. If the suppliers should decide to shut down the software completely, an email
				message will be sent to the stored email addresses. The software will not be deleted before a period of 4 weeks has passed.
				<br />
				Belgian law shall apply. 1. May 2017
			</Grid>
		),
		henco_fr: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Supplier</h3>
				The software is provided free of charge by
				<br />
				Henco Floor NV
				<br />
				Toekomstlaan 22
				<br />
				2200 Herentals | Belgium
				<br />
				Web: www.henco.be
				<br />
				Email: project@hencofloor.be
				<br />
				Commercial registration number: BE0862367117
				<br />
				VAT ID-No.: BE0443598222
				<br />
				Managing Directors: Wim Verhoeven
				<br />
				<br />
				Application web développée et fournie par
				<br />
				LINEAR GmbH
				<br />
				Im Suesterfeld 20
				<br />
				52072 Aachen
				<br />
				ALLEMAGNE
				<br />
				Registre du commerce : Amtsgericht Aachen HRB 5701 HRB 5701
				<br />
				N° d'identification TVA : DE16036747433.
				<br />
				Conseil d'administration : Manfred Waluga, Javier Castell Codesal et Juergen Frantzen
				<br />
				<br />
				<br />
				<h3> 2. Purpose of the software </h3>
				With this free offer, the suppliers want to give specialist craftsmen and other interested parties the opportunity to get an overview of which materials are
				required for the installation of a Henco Floor underfloor heating system. The software does not contain any specialist planning. It cannot take individual features
				of a building project into consideration. For this reason, the data which is automatically detected by the software can only serve as an indication rather than a
				binding basis for an order. The supplier is therefore not liable for the accuracy and completeness of the data.
				<h3> 3. Personal data </h3>
				This application uses Google-Analytics, a web analysis service from Google Inc. ("Google"). Google-Analytics uses so-called "cookies": text files which are saved on
				your computer and enables analysis of your use of the application. The information created by the cookie about your use of the website (including your IP address)
				is transmitted to a Google server in the USA and saved there. Google will use this information to evaluate your use of the application, to compile reports about
				your activities for the suppliers and to provide further services related to use of the application and the internet. Google may also transmit this information to
				third parties in so far as this is legally required or in so far as third parties process this data on behalf of Google. Google will never link your IP address with
				other data saved by Google. You can prevent installation of cookies using corresponding browser software settings. We would like to point out however, that in this
				case not all the functions of this application will be able to be used to their full extent. Through confirmation of this licence agreement you declare your
				agreement with the processing of the data collected about you by Google in the way described above and for the above-mentioned purpose. In order to use the software
				it is necessary to enter a valid email address and a password of your choice. This data is automatically saved and will not be forwarded to third parties. The
				suppliers will not use the stored email addresses for advertising purposes. However, the suppliers reserve the right to use the stored email addresses to
				communicate important information related to this planning software, such as changes to the conditions of use, extensions to the program or software shutdown.
				<h3> 4. Creation of projects </h3>
				The software enables any number of projects to be created. These can be saved in a virtual environment. The suppliers have no storage or archiving obligations. For
				this reason, users should save the project data in question themselves. It is possible in principle for the suppliers to access the stored data. Project data will
				not be forwarded to third parties. However, the suppliers reserve the right to query the necessity and suitability of the data use. This gives the suppliers the
				possibility of preparing necessary evaluations and statistics.
				<h3> 5. Use of cookies </h3>
				The software uses so-called cookies to be able to make use of the software attractive and permit specific functions. These are small text files which are saved on
				your terminal. Some of the cookies are deleted at the end of the browser session, i.e. once you close your browser. Other cookies remain on your terminal. You can
				adjust your browser settings in such a way that you are informed about cookies being set and can decide individually about whether to accept their use. The
				suppliers cannot guarantee that the software will work perfectly if the use of cookies is not permitted.
				<h3> 6. Data protection </h3>
				You can object to the use, processing and forwarding of your personal data at any time by sending an informal letter by post to the address stipulated in section 1
				of these conditions or by sending an email to the named address of the provider. In accordance with the BDSG (Federal Data Protection Act)” you have a right to free
				access to information about the personal data saved about you as well as possibly a right to correction, disabling or deletion of this data. We will no longer use
				the data concerned upon receipt of your objection. In addition, you have the possibility of deleting your account yourself.
				<h3> 7. Miscellaneous </h3>
				The suppliers retain the right to shut down the software at any time. No guarantee can be given for the permanent availability of the data saved on the accounts.
				The software cannot be used in particular during maintenance work or disruptions. If the suppliers should decide to shut down the software completely, an email
				message will be sent to the stored email addresses. The software will not be deleted before a period of 4 weeks has passed.
				<br />
				Belgian law shall apply. 1. May 2017
			</Grid>
		),
		henco_nl: (
			<Grid container direction="column" sx={GridPadding}>
				<h3>1. Leverancier</h3>
				De software wordt gratis aangeboden door
				<br />
				Henco Floor NV
				<br />
				Toekomstlaan 22
				<br />
				2200 Herentals | Belgium
				<br />
				Web: www.henco.be
				<br />
				Email: project@hencofloor.be
				<br />
				Ondernemingsnummer: BE0862367117
				<br />
				BTW nummer: BE0443598222
				<br />
				Algemeen Directeur: Wim Verhoeven
				<br />
				<br />
				Medeleverancier van de software is ook
				<br />
				LINEAR GmbH
				<br />
				lm Süsterfeld 20
				<br />
				52072 Aachen | Germany
				<br />
				Web: www.linear.de
				<br />
				Email: info@linear.de
				<br />
				Adres: Amtsgericht Aachen HRB 5701
				<br />
				BTW nummer: DE160367433
				<br />
				Algemene Directie: Manfred Waluga, Javier Castell-Codesal, Jürgen Frantzen
				<br />
				<h3>2. Doel van deze software</h3>
				Met deze gratis service willen wij vakmensen en geïnteresseerden de mogelijkheid bieden om te bepalen welke materialen nodig zijn voor de installatie van een Henco
				Floor vloerverwarmings-systeem. Deze software is eenvoudig in gebruik en geschikt voor iedereen. Deze software kan geen specifieke gebouweigenschappen in acht
				nemen. Voor deze rede zijn de bepaalde materialen een indicatie en geen vaste basis om als order toe te passen.
				<h3>3. Persoonlijke data</h3>
				Deze software maakt gebruik van Google-Analytics, een web-service van Google Inc. (“Google”). Google-Analytics gebruikt cookies (= tekstdocumenten die lokaal op uw
				pc worden bewaard waardoor u deze toepassing kan uitvoeren). De informatie betreffende uw gebruik van de website (uw IP adres incl.) gecreëerd door deze cookies
				wordt verzonden naar een Google-server in de USA en daar bewaard. Google gebruikt deze informatie om uw gebruik van de toepassing en uw activiteiten te evalueren en
				zodoende hun internetdiensten verder te ontwikkelen. Google kan deze informatie ook ter beschikking stellen van een derde partij in zover dat dit wettelijk vereist
				is of delen met een partij die in naam van Google data verwerkt. Google zal nooit uw IP adres koppelen aan andere informatie bewaard door Google. U kan de
				installatie van cookies vermijden door aanpassing van uw browserinstellingen. Wij merken op dat deze instellingen mogelijke invloed hebben op de werking van de
				software. Met bevestiging van de licentieovereenkomst gaat u akkoord met de verwerking van verzamelde informatie door Google als hierboven omschreven en dit voor
				bovengenoemde doeleinden. Geregistreerde email-adressen worden niet gebruikt voor advertenties. Echter behoud de fabrikant zich steeds het recht belangrijke
				informatie te delen betreffende deze software (bijvoorbeeld, andere gebruiksvoorwaarden, uitbreidingen of stopzetting van de software)
				<h3>4. Aanmaak van projecten</h3>
				Deze software laat toe om projecten aan te maken naar wens. Deze projecten kunnen opgeslagen worden in een virtuele omgeving. Fabrikanten hebben geen opslag- en/of
				archiverings-verplichtingen. Voor deze reden dienen gebruikers zelf de projecten op te slaan en te beheren. De fabrikant kan steeds de aangemaakte projecten
				inkijken. Een project wordt nooit doorgestuurd naar een derde partij. De fabrikant behoudt zich het recht om de noodzaak en geschiktheid van het gebruik in vraag te
				stellen. Dit Geeft de fabrikant de mogelijkheid om nodige evaluaties van het gebruik op te maken.
				<h3>5. Gebruik van cookies</h3>
				De software gebruikt zogenaamde cookies. Dit zijn kleine tekstbestanden die lokaal worden bewaard op uw apparaat. Sommige cookies worden verwijderd wanneer u uw
				browsersessie afsluit. Andere cookies blijven bewaard. U kan uw browser-instellingen aanpassen waardoor u wordt geïnformeerd en kan beslissen of dat deze cookies
				worden toegestaan. De fabrikant kan niet garanderen dat de software perfect functioneert wanneer het gebruik van cookies niet wordt toegestaan.
				<h3>6. Bescherming gegevens</h3>U kan steeds weigeren dat uw persoonlijke gegevens worden verwerkt en doorgestuurd, door een informele brief of email te versturen
				naar bovengenoemd adres van de fabrikant. In associatie met BDSG (Federal Data Protection Act)” heeft u het recht tot vrije toegang tot informatie betreffende het
				gebruik van uw persoonlijke gegevens als ook de mogelijkheid om deze informatie te wijzigen, verwijderen, niet beschikbaar te stellen voor gebruik.
				<h3>7. Andere vermeldingen</h3>
				De fabrikant behoudt het recht om de software buiten gebruik te stellen ten alle tijden. Er wordt geen garantie gegeven dat gegevens gekoppeld aan een account ten
				alle tijden worden bewaard. De software is buiten gebruik tijdens onderhoud en storingen. Als de fabrikant zou beslissen om de software definitief stop te zetten
				zal een email-bericht worden verstuurd met deze melding naar alle gekende adressen. De software zal hierop volgend pas na een periode van 4 weken stop gezet worden.
				<br />
				Belgische wetgeving is van toepassing.
				<br />1 Mei 2017
			</Grid>
		),
		henco_ru: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Supplier</h3>
				The software is provided free of charge by
				<br />
				Henco Floor NV <br />
				Toekomstlaan 22 <br />
				2200 Herentals | Belgium <br />
				Web: www.henco.be <br />
				Email: project@hencofloor.be <br />
				Commercial registration number: BE0862367117 <br />
				VAT ID-No.: BE0443598222 <br />
				Managing Directors: Wim Verhoeven <br />
				<br />
				Supplier of the software is also
				<br />
				LINEAR GmbH <br />
				Im Süsterfeld 20
				<br />
				52072 Aachen/Germany
				<br />
				Web: www.linear.de
				<br />
				Email: info@linear.de
				<br />
				Commercial registration number: District Court Aachen HRA 5701 <br />
				VAT ID-No.: DE160367433. <br />
				Managing directors: Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen
				<br />
				<h3> 2. Purpose of the software </h3>
				With this free offer, the suppliers want to give specialist craftsmen and other interested parties the opportunity to get an overview of which materials are
				required for the installation of a Henco Floor underfloor heating system. The software does not contain any specialist planning. It cannot take individual features
				of a building project into consideration. For this reason, the data which is automatically detected by the software can only serve as an indication rather than a
				binding basis for an order. The supplier is therefore not liable for the accuracy and completeness of the data.
				<h3> 3. Personal data </h3>
				This application uses Google-Analytics, a web analysis service from Google Inc. ("Google"). Google-Analytics uses so-called "cookies": text files which are saved on
				your computer and enables analysis of your use of the application. The information created by the cookie about your use of the website (including your IP address)
				is transmitted to a Google server in the USA and saved there. Google will use this information to evaluate your use of the application, to compile reports about
				your activities for the suppliers and to provide further services related to use of the application and the internet. Google may also transmit this information to
				third parties in so far as this is legally required or in so far as third parties process this data on behalf of Google. Google will never link your IP address with
				other data saved by Google. You can prevent installation of cookies using corresponding browser software settings. We would like to point out however, that in this
				case not all the functions of this application will be able to be used to their full extent. Through confirmation of this licence agreement you declare your
				agreement with the processing of the data collected about you by Google in the way described above and for the above-mentioned purpose. In order to use the software
				it is necessary to enter a valid email address and a password of your choice. This data is automatically saved and will not be forwarded to third parties. The
				suppliers will not use the stored email addresses for advertising purposes. However, the suppliers reserve the right to use the stored email addresses to
				communicate important information related to this planning software, such as changes to the conditions of use, extensions to the program or software shutdown.
				<h3> 4. Creation of projects </h3>
				The software enables any number of projects to be created. These can be saved in a virtual environment. The suppliers have no storage or archiving obligations. For
				this reason, users should save the project data in question themselves. It is possible in principle for the suppliers to access the stored data. Project data will
				not be forwarded to third parties. However, the suppliers reserve the right to query the necessity and suitability of the data use. This gives the suppliers the
				possibility of preparing necessary evaluations and statistics.
				<h3> 5. Use of cookies </h3>
				The software uses so-called cookies to be able to make use of the software attractive and permit specific functions. These are small text files which are saved on
				your terminal. Some of the cookies are deleted at the end of the browser session, i.e. once you close your browser. Other cookies remain on your terminal. You can
				adjust your browser settings in such a way that you are informed about cookies being set and can decide individually about whether to accept their use. The
				suppliers cannot guarantee that the software will work perfectly if the use of cookies is not permitted.
				<h3> 6. Data protection </h3>
				You can object to the use, processing and forwarding of your personal data at any time by sending an informal letter by post to the address stipulated in section 1
				of these conditions or by sending an email to the named address of the provider. In accordance with the BDSG (Federal Data Protection Act)” you have a right to free
				access to information about the personal data saved about you as well as possibly a right to correction, disabling or deletion of this data. We will no longer use
				the data concerned upon receipt of your objection. In addition, you have the possibility of deleting your account yourself.
				<h3> 7. Miscellaneous </h3>
				The suppliers retain the right to shut down the software at any time. No guarantee can be given for the permanent availability of the data saved on the accounts.
				The software cannot be used in particular during maintenance work or disruptions. If the suppliers should decide to shut down the software completely, an email
				message will be sent to the stored email addresses. The software will not be deleted before a period of 4 weeks has passed.
				<br />
				Belgian law shall apply. 1. May 2017
			</Grid>
		),
		ivt_de: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos von der
				<br /> IVT GmbH & Co. KG Gewerbering Nord 5 91189 Rohr
				<br /> Sitz: Rohr
				<br /> Handelsregister: Amtsgericht Schwabach HRA 10791
				<br /> USt-ID-Nr.: DE170458863
				<br /> Persönlich haftende Gesellschafterin: IVT Installations- und Verbindungstechnik Verwaltungs GmbH Geschäftsführer: Ingrid Zürn, Ralf Stottok
				<br />
				<br /> Anbieter der Software ist auch die
				<br /> LINEAR Gesellschaft für konstruktives Design mbH
				<br /> Im Süsterfeld 20
				<br /> 52072 Aachen
				<br /> HR: Amtsgericht Aachen HRB 5701
				<br /> USt-ID-Nr.: DE160367433.
				<br /> Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<h3> 2. Zweck der Software</h3>
				Mit diesem kostenlosen Angebot wollen die Anbieter den Fachhandwerker und anderen Interessierten die Möglichkeit bieten sich einen Überblick zu verschaffen, welche
				Materialien bei dem Einbau einer PRINETO Fußbodenheizung benötigt werden. Die Software ersetzt keine Fachplanung. Sie kann auch individuelle Besonderheiten eines
				Bauvorhabens nicht berücksichtigen. Daher sind die Daten, die durch die Software automatisch ermittelt werden, nur als Anhaltspunkt, nicht aber als eine
				verbindliche Grundlage für eine Bestellung zu sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<h3> 3. Personenbezogene Daten</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der IVT GmbH & Co. KG
				</a>
				<h3> 4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt werden können. Diese können virtuell abgespeichert werden. Die Anbieter haben keine Aufbewahrungs-
				oder Archivierungspflicht. Aus diesem Grund sollte der Anwender die ermittelten Projektdaten bei sich abspeichern. Grundsätzlich besteht für die Anbieter die
				Möglichkeit zum Zugriff auf die hinterlegten Daten. Projektdaten werden nicht an Dritte weitergegeben. Allerdings behalten es sich die Anbieter vor eine Abfrage
				durchzuführen, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen. Dadurch erhalten die Anbieter die Möglichkeit notwendige Auswertungen und Statistiken zu
				erstellen.
				<h3> 5. Einsatz von Cookies</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der IVT GmbH & Co. KG
				</a>
				<h3> 6. Datenschutz</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der IVT GmbH & Co. KG
				</a>
				<h3> 7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht. <br />
				Es gilt deutsches Recht.
				<br /> Stand 31.12.2021
			</Grid>
		),
		ivt_en: (
			<Grid container direction="column" sx={GridPadding}>
				<h3>1. Supplier</h3>
				The software is provided free of charge by
				<br />
				IVT GmbH &amp; Co. KG Gewerbering Nord 5 91189 Rohr/Germany
				<br />
				Headquarters: Rohr/Germany
				<br />
				Commercial Register: District Court Schwabach HRA 10791
				<br />
				VAT ID no.: DE170458863
				<br />
				General partner: IVT Installations- und Verbindungstechnik Verwaltungs GmbH
				<br />
				Managing Directors: Ingrid Zürn, Ralf Stottok Supplier of the software is also
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen/Germany
				<br />
				CR: District Court Aachen HRA 5701
				<br />
				VAT ID no.: DE160367433.
				<br />
				The company is represented by its managing directors Messrs. Manfred Waluga, Javier Castell-Codesal, Jürgen Frantzen.
				<h3>2. Purpose of the software</h3>
				With this free offer, the suppliers want to give specialist craftsmen and other interested parties the opportunity to get an overview of which materials are
				required for the installation of a PRINETO underfloor heating system. The software does not contain any specialist planning. It cannot take individual features of a
				building project into consideration. For this reason, the data which is automatically detected by the software can only serve as an indication rather than a binding
				basis for an order. The supplier is therefore not liable for the accuracy and completeness of the data.
				<h3>3. Personal data</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					See the privacy policy of IVT GmbH & Co. KG
				</a>
				<h3>4. Creation of projects</h3>
				The software enables any number of projects to be created. These can be saved in a virtual environment. The suppliers have no storage or archiving obligations. For
				this reason, users should save the project data in question themselves. It is possible in principle for the suppliers to access the stored data. Project data will
				not be forwarded to third parties. However, the suppliers reserve the right to query the necessity and suitability of the data use. This gives the suppliers the
				possibility of preparing necessary evaluations and statistics.
				<h3>5. Use of cookies</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					See the privacy policy of IVT GmbH & Co. KG
				</a>
				<h3>6. Data protection</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					See the privacy policy of IVT GmbH & Co. KG
				</a>
				<h3>7. Miscellaneous</h3>
				The suppliers retain the right to shut down the software at any time. No guarantee can be given for the permanent availability of the data saved on the accounts.
				The software cannot be used in particular during maintenance work or disruptions. If the suppliers should decide to shut down the software completely, an email
				message will be sent to the stored email addresses. The software will not be deleted before a period of 4 weeks has passed. <br />
				German law shall apply.
				<br />
				As of 31.12.2021
			</Grid>
		),
		ivt_ru: (
			<Grid container direction="column" sx={GridPadding}>
				<h3>1. Поставщик</h3>
				Программа бесплатно предоставляется фирмой:
				<br />
				IVT GmbH &amp; Co. KG Gewerbering Nord 5 91189 Rohr
				<br />
				в г. Рор
				<br />
				Торговый регистр: Окружной суд Швабах HRA 10791
				<br />
				Налоговый №.: DE170458863
				<br />
				Лично ответственный партнер: IVT Installations- und Verbindungstechnik Verwaltungs GmbH, руководители:
				<br />
				Ингрид Цюрн, Вольфганг Марк
				<br />
				Поставщиком программного обеспечения является также фирма
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20 52072 Aachen
				<br />
				Торговый регистр Aachen HRB 5701
				<br />
				Налоговый №.: DE160367433.
				<br />
				Обществом руководят Манфред Валуга, Хавьер Кастель Кодезаль, Юрген Франтцен.
				<h3>2. Цель программного обеспечения</h3>
				Этим бесплатным предложением поставщики хотят предоставить возможность квалифицированным специалистам получит обзор материалов, необходимых для монтажа системы
				поверхностного отопления PRINETO. Программное обеспечение не заменяет профессионального планирования. Она не может также предусмотреть особенностей строительного
				объекта. Поэтому данные, автоматически вычисленные этим программным обеспечением, надо рассматривать только как ориентировочные, а не как обязательными для заказа
				матириалов. Поставщики программного обеспечения не несут ответственность за правильность и полноту данных.
				<h3>3. Личные данные</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					См. Политику конфиденциальности IVT GmbH & Co. KG
				</a>
				<h3>4. Создание проектов</h3>
				Программное обеспечение позволяет создание любого количества проектов. Они могут быть практически сохранены. Поставщики не обязаны их хранить и архивировать. По
				этой причине пользователь должен сам сохранять себе созданные проекты. В принципе поставщик имеет возможность доступа к сохранённым данным. Данные проектов не
				передаются третьим лицам. Тем не менее поставщик оставляет за собой право провести запрос, чтобы выявить вопрос о необходимости и эксплуатационной пригодности
				программного обеспечения. Это даёт возможность поставщику составить соответствующие отчеты и статистики.
				<h3>5. Использование Cookie</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					См. Политику конфиденциальности IVT GmbH & Co. KG
				</a>
				<h3>6.Защита данных</h3>
				<a href="https://www.ivt-group.com/datenschutz" target="_blank" rel="noopener noreferrer">
					См. Политику конфиденциальности IVT GmbH & Co. KG
				</a>
				<h3>7. Прочее</h3>
				Поставщики оставляют за собой право в любое время отключить программное обеспечение. Не может быть также гарантирована постоянное наличие под вашим аккаунтом
				сохранённых данных. Программное обеспечение не может быть в частности использоваться во время технического обслуживания или сбоев. В случае, если поставщик решит
				полностью отключить программное обеспечение, то на ваш электронный адрес будет отправлено сообщение. Программное обеспечение будет отключено через 4 недели после
				отправления сообщения. <br />
				Действует немецкое право.
				<br />
				Положение на 01.03.2015
			</Grid>
		),
		//TODO
		kermi_de: <Grid container direction="column" sx={GridPadding}></Grid>,
		"p&m_de": (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos von der
				<br /> PFEIFFER & MAY SE
				<br /> Sitz: Karlsruhe
				<br /> Registergericht: Amtsgericht Mannheim HRB712580
				<br /> Umsatzsteuer-Ident-Nr.: DE160290082
				<br /> Persönlich haftende Gesellschafterin:PFEIFFER & MAY SE Vertretungsberechtigter Vorstand: Ulf Steinhart, Markus Bury
				<br />
				<br /> Anbieter der Software ist auch die
				<br /> LINEAR Gesellschaft für konstruktives Design mbH
				<br /> Im Süsterfeld 20
				<br /> 52072 Aachen
				<br /> HR: Amtsgericht Aachen HRB 5701
				<br /> USt-ID-Nr.: DE160367433.
				<br /> Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<h3> 2. Zweck der Software</h3>
				Die Web-Applikation unterstützt ausgewählte Mitarbeiter des Unternehmens bei der Auslegung von XtraFloor Fußbodenheizungsinstallationen. Die Software ersetzt keine
				Fachplanung. Sie kann auch individuelle Besonderheiten eines Bauvorhabens nicht berücksichtigen. Daher sind die Daten, die durch die Software automatisch ermittelt
				werden, nur als Anhaltspunkt, nicht aber als eine verbindliche Grundlage für eine Bestellung zu sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und
				Vollständigkeit der Daten.
				<h3> 3. Personenbezogene Daten</h3>
				<a href="https://pfeiffer-may.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der PFEIFFER & MAY SE
				</a>
				<h3> 4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt werden können. Diese können virtuell abgespeichert werden. Die Anbieter haben keine Aufbewahrungs-
				oder Archivierungspflicht. Aus diesem Grund sollte der Anwender die ermittelten Projektdaten bei sich abspeichern. Grundsätzlich besteht für die Anbieter die
				Möglichkeit zum Zugriff auf die hinterlegten Daten. Projektdaten werden nicht an Dritte weitergegeben. Allerdings behalten es sich die Anbieter vor eine Abfrage
				durchzuführen, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen. Dadurch erhalten die Anbieter die Möglichkeit notwendige Auswertungen und Statistiken zu
				erstellen.
				<h3> 5. Einsatz von Cookies</h3>
				<a href="https://pfeiffer-may.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der PFEIFFER & MAY SE
				</a>
				<h3> 6. Datenschutz</h3>
				<a href="https://pfeiffer-may.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
					Siehe Datenschutzerklärung der PFEIFFER & MAY SE
				</a>
				<h3> 7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht.
				<br />
				<br />
				Es gilt deutsches Recht.
				<br />
				Stand 01.12.2021
			</Grid>
		),
		purmo_de: (
			<Grid container direction="column" sx={GridPadding}>
				<p>
					<b>
						<p>
							<strong>1. &nbsp; &nbsp; &nbsp;Anbieter</strong>
						</p>

						<p>
							Die Software wird kostenlos zur Verf&uuml;gung gestellt von:
							<br />
							Purmo Group
							<br />
							Bulevardi 46
							<br />
							P.O. Box 115
							<br />
							FI-00121 Helsinki
							<br />
							Finland
							<br />
							Tel.: +358 9 6188 3188
							<br />
							www.purmogroup.com
						</p>

						<p>
							Anbieter der Software ist auch die
							<br />
							LINEAR Gesellschaft f&uuml;r konstruktives Design mbH
							<br />
							Im S&uuml;sterfeld 20
							<br />
							52072 Aachen
							<br />
							HR: Amtsgericht Aachen HRB 5701
							<br />
							USt-ID-Nr.: DE160367433.
							<br />
							Die Gesellschaft wird durch Ihre Gesch&auml;ftsf&uuml;hrer, Herrn Manfred Waluga, Javier Castell Codesal, J&uuml;rgen Frantzen, vertreten.
						</p>

						<p>
							<strong>2. Zweck der Software</strong>
						</p>

						<p>
							Mit diesem kostenlosen Angebot erm&ouml;glichen die Anbieter Fachhandwerkern und anderen Interessierten sich einen schnellen &Uuml;berblick zu
							verschaffen, welche Materialien bei dem Einbau einer Purmo oder Vogel&amp;Noot Fl&auml;chenheizung ben&ouml;tigt werden. Die Software ersetzt keine
							Fachplanung. Sie kann auch die individuellen Besonderheiten eines Bauvorhabens nicht ber&uuml;cksichtigen. Daher sind die Ergebnisse, die durch die
							Software automatisch ermittelt werden, auch nur als unverbindlicher Vorschlag, nicht aber als eine verbindliche Grundlage f&uuml;r eine Bestellung zu
							verstehen. Die Anbieter haften daher auch nicht f&uuml;r die Richtigkeit und Vollst&auml;ndigkeit der ausgegebenen Daten und Preise.
						</p>

						<p>
							<strong>3. Personenbezogene Daten</strong>
						</p>

						<p>
							Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. (&bdquo;Google&ldquo;). Google-Analytics verwendet sog.
							&bdquo;Cookies&ldquo; Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie
							erm&ouml;glicht. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website (einschlie&szlig;lich Ihrer IP-Adresse) wird an
							einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Applikation
							auszuwerten, um Reports &uuml;ber die stattgefundenen Aktivit&auml;ten f&uuml;r die Anbieter zusammen zu stellen und um weitere mit der Nutzung der
							Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte &uuml;bertragen,
							sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
							anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht s&auml;mtliche Funktionen dieser
							Applikation vollumf&auml;nglich genutzt werden k&ouml;nnen. Durch die Best&auml;tigung dieser Lizenzvereinbarung erkl&auml;ren Sie sich mit der
							Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Zur
							Benutzung der Software ist es zudem erforderlich, eine g&uuml;ltige Email-Adresse einzugeben und ein selbstgew&auml;hltes Passwort zu verwenden. Diese
							Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten au&szlig;erhalb der Software findet
							nicht statt. Die Anbieter werden die hinterlegten E-Mail-Adressen auch nicht f&uuml;r Werbezwecke verwenden. Allerdings behalten es sich die Anbieter
							vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei &Auml;nderung der Benutzerbedingungen, Programmerweiterungen
							oder bei Abschalten der Software hier&uuml;ber &uuml;ber die hinterlegte E-Mail-Adresse zu informieren.
						</p>

						<p>
							<strong>4. Anlegen von Projekten</strong>
						</p>

						<p>
							Die Software erm&ouml;glicht, dass beliebig viele Projekte angelegt werden k&ouml;nnen. Diese k&ouml;nnen virtuell abgespeichert werden. Die Anbieter
							haben keine Aufbewahrungs- oder Archivierungspflicht f&uuml;r diese Projektdaten. Aus diesem Grund wird empfohlen, dass der Anwender die ermittelten
							Projektdaten bei sich abspeichert. Grunds&auml;tzlich besteht f&uuml;r die Anbieter die M&ouml;glichkeit zum Zugriff auf die hinterlegten Daten. Die
							Anbieter werden hiervon aber keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht f&uuml;r eigene Zwecke ausgewertet oder an Dritte
							weitergegeben. Allerdings behalten es sich die Anbieter vor eine Abfrage durchzuf&uuml;hren, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen.
							Dadurch erhalten die Anbieter die M&ouml;glichkeit notwendige Auswertungen und Statistiken zu erstellen.
						</p>

						<p>
							<strong>5. Einsatz von Cookies</strong>
						</p>

						<p>
							Um die Nutzung der Software attraktiv gestalten zu k&ouml;nnen und bestimmte Funktionen zu erm&ouml;glichen, verwendet die Software sog. Cookies.
							Hierbei handelt es sich um kleine Textdateien, die auf ihrem Endger&auml;t abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung,
							also nach Schlie&szlig;en Ihres Browsers wieder gel&ouml;scht. Andere Cookies verbleiben auf Ihrem Endger&auml;t. Sie k&ouml;nnen Ihren Browser so
							einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und &uuml;ber deren Annahme im Einzelnen entscheiden. Die Anbieter k&ouml;nnen
							nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht gestattet wird.
						</p>

						<p>
							<strong>6. Datenschutz</strong>
						</p>

						<p>Wir verarbeiten personenbezogene Daten in &Uuml;bereinstimmung mit unseren Datenschutzhinweisen.</p>

						<p>
							<strong>7. Sonstiges</strong>
						</p>

						<p>
							Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie f&uuml;r die dauerhafte Verf&uuml;gbarkeit der unter
							den Accounts hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder St&ouml;rungen nicht verwendet werden.
							Sollten die Anbieter sich entscheiden, die Software vollst&auml;ndig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email
							versandt. Die Software wird nicht vor Ablauf von 4 Wochen gel&ouml;scht.
						</p>

						<p>Es gilt europ&auml;isches Recht.</p>

						<p>Stand 10.10.2022</p>
					</b>
				</p>
			</Grid>
		),
		cosmo_de: (
			<Grid container direction="column" sx={GridPadding}>
				<p>
					<b>
						<p>
							<strong>1. &nbsp; &nbsp; &nbsp;Anbieter</strong>
						</p>

						<p>
							Die Software wird kostenlos zur Verfügung gestellt von:
							<br />
							COSMO GmbH
							<br />
							Brandstücken 31
							<br />
							22549 Hamburg
							<br />
							Deutschland
							<br />
							Telefon: 040/80030430
							<br />
							E-Mail: info@cosmo-info.de
							<br />
							www.cosmo-info.de
						</p>

						<p>
							Anbieter der Software ist auch die
							<br />
							LINEAR Gesellschaft f&uuml;r konstruktives Design mbH
							<br />
							Im S&uuml;sterfeld 20
							<br />
							52072 Aachen
							<br />
							HR: Amtsgericht Aachen HRB 5701
							<br />
							USt-ID-Nr.: DE160367433.
							<br />
							Die Gesellschaft wird durch Ihre Gesch&auml;ftsf&uuml;hrer, Herrn Manfred Waluga, Javier Castell Codesal, J&uuml;rgen Frantzen, vertreten.
						</p>

						<p>
							<strong>2. Zweck der Software</strong>
						</p>

						<p>
							Mit diesem kostenlosen Angebot erm&ouml;glichen die Anbieter Fachhandwerkern und anderen Interessierten sich einen schnellen &Uuml;berblick zu
							verschaffen, welche Materialien bei dem Einbau einer Purmo oder Vogel&amp;Noot Fl&auml;chenheizung ben&ouml;tigt werden. Die Software ersetzt keine
							Fachplanung. Sie kann auch die individuellen Besonderheiten eines Bauvorhabens nicht ber&uuml;cksichtigen. Daher sind die Ergebnisse, die durch die
							Software automatisch ermittelt werden, auch nur als unverbindlicher Vorschlag, nicht aber als eine verbindliche Grundlage f&uuml;r eine Bestellung zu
							verstehen. Die Anbieter haften daher auch nicht f&uuml;r die Richtigkeit und Vollst&auml;ndigkeit der ausgegebenen Daten und Preise.
						</p>

						<p>
							<strong>3. Personenbezogene Daten</strong>
						</p>

						<p>
							Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. (&bdquo;Google&ldquo;). Google-Analytics verwendet sog.
							&bdquo;Cookies&ldquo; Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie
							erm&ouml;glicht. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website (einschlie&szlig;lich Ihrer IP-Adresse) wird an
							einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Applikation
							auszuwerten, um Reports &uuml;ber die stattgefundenen Aktivit&auml;ten f&uuml;r die Anbieter zusammen zu stellen und um weitere mit der Nutzung der
							Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte &uuml;bertragen,
							sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
							anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht s&auml;mtliche Funktionen dieser
							Applikation vollumf&auml;nglich genutzt werden k&ouml;nnen. Durch die Best&auml;tigung dieser Lizenzvereinbarung erkl&auml;ren Sie sich mit der
							Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Zur
							Benutzung der Software ist es zudem erforderlich, eine g&uuml;ltige Email-Adresse einzugeben und ein selbstgew&auml;hltes Passwort zu verwenden. Diese
							Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten au&szlig;erhalb der Software findet
							nicht statt. Die Anbieter werden die hinterlegten E-Mail-Adressen auch nicht f&uuml;r Werbezwecke verwenden. Allerdings behalten es sich die Anbieter
							vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei &Auml;nderung der Benutzerbedingungen, Programmerweiterungen
							oder bei Abschalten der Software hier&uuml;ber &uuml;ber die hinterlegte E-Mail-Adresse zu informieren.
						</p>

						<p>
							<strong>4. Anlegen von Projekten</strong>
						</p>

						<p>
							Die Software erm&ouml;glicht, dass beliebig viele Projekte angelegt werden k&ouml;nnen. Diese k&ouml;nnen virtuell abgespeichert werden. Die Anbieter
							haben keine Aufbewahrungs- oder Archivierungspflicht f&uuml;r diese Projektdaten. Aus diesem Grund wird empfohlen, dass der Anwender die ermittelten
							Projektdaten bei sich abspeichert. Grunds&auml;tzlich besteht f&uuml;r die Anbieter die M&ouml;glichkeit zum Zugriff auf die hinterlegten Daten. Die
							Anbieter werden hiervon aber keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht f&uuml;r eigene Zwecke ausgewertet oder an Dritte
							weitergegeben. Allerdings behalten es sich die Anbieter vor eine Abfrage durchzuf&uuml;hren, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen.
							Dadurch erhalten die Anbieter die M&ouml;glichkeit notwendige Auswertungen und Statistiken zu erstellen.
						</p>

						<p>
							<strong>5. Einsatz von Cookies</strong>
						</p>

						<p>
							Um die Nutzung der Software attraktiv gestalten zu k&ouml;nnen und bestimmte Funktionen zu erm&ouml;glichen, verwendet die Software sog. Cookies.
							Hierbei handelt es sich um kleine Textdateien, die auf ihrem Endger&auml;t abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung,
							also nach Schlie&szlig;en Ihres Browsers wieder gel&ouml;scht. Andere Cookies verbleiben auf Ihrem Endger&auml;t. Sie k&ouml;nnen Ihren Browser so
							einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und &uuml;ber deren Annahme im Einzelnen entscheiden. Die Anbieter k&ouml;nnen
							nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht gestattet wird.
						</p>

						<p>
							<strong>6. Datenschutz</strong>
						</p>

						<p>Wir verarbeiten personenbezogene Daten in &Uuml;bereinstimmung mit unseren Datenschutzhinweisen.</p>

						<p>
							<strong>7. Sonstiges</strong>
						</p>

						<p>
							Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie f&uuml;r die dauerhafte Verf&uuml;gbarkeit der unter
							den Accounts hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder St&ouml;rungen nicht verwendet werden.
							Sollten die Anbieter sich entscheiden, die Software vollst&auml;ndig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email
							versandt. Die Software wird nicht vor Ablauf von 4 Wochen gel&ouml;scht.
						</p>

						<p>Es gilt europ&auml;isches Recht.</p>

						<p>Stand 10.10.2022</p>
					</b>
				</p>
			</Grid>
		),
		vun_de: (
			<Grid container direction="column" sx={GridPadding}>
				<p>
					<b>
						<p>
							<strong>1. &nbsp; &nbsp; &nbsp;Anbieter</strong>
						</p>

						<p>
							Die Software wird kostenlos zur Verf&uuml;gung gestellt von:
							<br />
							Purmo Group
							<br />
							Bulevardi 46
							<br />
							P.O. Box 115
							<br />
							FI-00121 Helsinki
							<br />
							Finland
							<br />
							Tel.: +358 9 6188 3188
							<br />
							www.purmogroup.com
						</p>

						<p>
							Anbieter der Software ist auch die
							<br />
							LINEAR Gesellschaft f&uuml;r konstruktives Design mbH
							<br />
							Im S&uuml;sterfeld 20
							<br />
							52072 Aachen
							<br />
							HR: Amtsgericht Aachen HRB 5701
							<br />
							USt-ID-Nr.: DE160367433.
							<br />
							Die Gesellschaft wird durch Ihre Gesch&auml;ftsf&uuml;hrer, Herrn Manfred Waluga, Javier Castell Codesal, J&uuml;rgen Frantzen, vertreten.
						</p>

						<p>
							<strong>2. Zweck der Software</strong>
						</p>

						<p>
							Mit diesem kostenlosen Angebot erm&ouml;glichen die Anbieter Fachhandwerkern und anderen Interessierten sich einen schnellen &Uuml;berblick zu
							verschaffen, welche Materialien bei dem Einbau einer Purmo oder Vogel&amp;Noot Fl&auml;chenheizung ben&ouml;tigt werden. Die Software ersetzt keine
							Fachplanung. Sie kann auch die individuellen Besonderheiten eines Bauvorhabens nicht ber&uuml;cksichtigen. Daher sind die Ergebnisse, die durch die
							Software automatisch ermittelt werden, auch nur als unverbindlicher Vorschlag, nicht aber als eine verbindliche Grundlage f&uuml;r eine Bestellung zu
							verstehen. Die Anbieter haften daher auch nicht f&uuml;r die Richtigkeit und Vollst&auml;ndigkeit der ausgegebenen Daten und Preise.
						</p>

						<p>
							<strong>3. Personenbezogene Daten</strong>
						</p>

						<p>
							Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. (&bdquo;Google&ldquo;). Google-Analytics verwendet sog.
							&bdquo;Cookies&ldquo; Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie
							erm&ouml;glicht. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website (einschlie&szlig;lich Ihrer IP-Adresse) wird an
							einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Applikation
							auszuwerten, um Reports &uuml;ber die stattgefundenen Aktivit&auml;ten f&uuml;r die Anbieter zusammen zu stellen und um weitere mit der Nutzung der
							Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte &uuml;bertragen,
							sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
							anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht s&auml;mtliche Funktionen dieser
							Applikation vollumf&auml;nglich genutzt werden k&ouml;nnen. Durch die Best&auml;tigung dieser Lizenzvereinbarung erkl&auml;ren Sie sich mit der
							Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Zur
							Benutzung der Software ist es zudem erforderlich, eine g&uuml;ltige Email-Adresse einzugeben und ein selbstgew&auml;hltes Passwort zu verwenden. Diese
							Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten au&szlig;erhalb der Software findet
							nicht statt. Die Anbieter werden die hinterlegten E-Mail-Adressen auch nicht f&uuml;r Werbezwecke verwenden. Allerdings behalten es sich die Anbieter
							vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei &Auml;nderung der Benutzerbedingungen, Programmerweiterungen
							oder bei Abschalten der Software hier&uuml;ber &uuml;ber die hinterlegte E-Mail-Adresse zu informieren.
						</p>

						<p>
							<strong>4. Anlegen von Projekten</strong>
						</p>

						<p>
							Die Software erm&ouml;glicht, dass beliebig viele Projekte angelegt werden k&ouml;nnen. Diese k&ouml;nnen virtuell abgespeichert werden. Die Anbieter
							haben keine Aufbewahrungs- oder Archivierungspflicht f&uuml;r diese Projektdaten. Aus diesem Grund wird empfohlen, dass der Anwender die ermittelten
							Projektdaten bei sich abspeichert. Grunds&auml;tzlich besteht f&uuml;r die Anbieter die M&ouml;glichkeit zum Zugriff auf die hinterlegten Daten. Die
							Anbieter werden hiervon aber keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht f&uuml;r eigene Zwecke ausgewertet oder an Dritte
							weitergegeben. Allerdings behalten es sich die Anbieter vor eine Abfrage durchzuf&uuml;hren, um den Bedarf und die Nutzungstauglichkeit zu hinterfragen.
							Dadurch erhalten die Anbieter die M&ouml;glichkeit notwendige Auswertungen und Statistiken zu erstellen.
						</p>

						<p>
							<strong>5. Einsatz von Cookies</strong>
						</p>

						<p>
							Um die Nutzung der Software attraktiv gestalten zu k&ouml;nnen und bestimmte Funktionen zu erm&ouml;glichen, verwendet die Software sog. Cookies.
							Hierbei handelt es sich um kleine Textdateien, die auf ihrem Endger&auml;t abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung,
							also nach Schlie&szlig;en Ihres Browsers wieder gel&ouml;scht. Andere Cookies verbleiben auf Ihrem Endger&auml;t. Sie k&ouml;nnen Ihren Browser so
							einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und &uuml;ber deren Annahme im Einzelnen entscheiden. Die Anbieter k&ouml;nnen
							nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht gestattet wird.
						</p>

						<p>
							<strong>6. Datenschutz</strong>
						</p>

						<p>Wir verarbeiten personenbezogene Daten in &Uuml;bereinstimmung mit unseren Datenschutzhinweisen.</p>

						<p>
							<strong>7. Sonstiges</strong>
						</p>

						<p>
							Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie f&uuml;r die dauerhafte Verf&uuml;gbarkeit der unter
							den Accounts hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder St&ouml;rungen nicht verwendet werden.
							Sollten die Anbieter sich entscheiden, die Software vollst&auml;ndig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email
							versandt. Die Software wird nicht vor Ablauf von 4 Wochen gel&ouml;scht.
						</p>

						<p>Es gilt europ&auml;isches Recht.</p>

						<p>Stand 10.10.2022</p>
					</b>
				</p>
			</Grid>
		),
		roth_de: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos bereitgestellt von der
				<br />
				<br />
				Roth Werke GmbH
				<br />
				Am Seerain 1
				<br />
				35232 Dautphetal
				<br />
				<br />
				Telefon: (+49) 6466/9 22-0
				<br />
				Telefax: (+49) 6466/9 22-1 00
				<br />
				<br />
				Internet: http://roth-werke.de
				<br />
				E-Mail: service@roth-werke.de
				<br />
				<br />
				Vertretungsberechtigte Geschäftsführer:
				<br />
				Alfred Kajewski, Christin Roth-Jäger
				<br />
				<br />
				Registergericht: Amtsgericht Marburg
				<br />
				Registernummer: HRB 2726
				<br />
				<br />
				Umsatzsteuer-Identifikationsnummer: DE811225861
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr. DE160367433
				<br />
				Die Gesellschaft wird durch die Geschäftsführer: Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<h3>2. Zweck der Software/Haftungsausschluss</h3>
				Mit diesem kostenlosen Angebot wollen die Anbieter Fachhandwerkern und anderen Interessierten einen Überblick verschaffen, welche Materialien bei dem Einbau einer
				Roth Fußbodenheizung benötigt werden. Die Software ersetzt keine Fachplanung. Sie kann auch individuelle Besonderheiten eines Bauvorhabens nicht berücksichtigen.
				Daher sind die Daten, die durch die Software automatisch ermittelt werden, nur als Anhaltspunkt, nicht aber als eine verbindliche Grundlage für eine Bestellung zu
				sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<h3>3. Personenbezogene Daten</h3>
				<a href="http://www.roth-werke.de/de/roth-datenschutz.htm" target="blank">
					Roth Datenschutz
				</a>
				<h3>4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt und virtuell abgespeichert werden können. Grundsätzlich besteht für die Anbieter die Möglichkeit zum
				Zugriff auf die hinterlegten Daten. Die Projektdaten werden nicht an Dritte weitergegeben.
				<h3>5. Einsatz von Cookies</h3>
				<a href="http://www.roth-werke.de/de/roth-datenschutz.htm" target="blank">
					Roth Datenschutz
				</a>
				<h3>6. Datenschutz</h3>
				<a href="http://www.roth-werke.de/de/roth-datenschutz.htm" target="blank">
					Roth Datenschutz
				</a>
				<h3>7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht.
				<br />
				Es gilt deutsches Recht.
				<br />
				Stand 25.05.2018
			</Grid>
		),
		roth_en: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				The software is supplied free of charge by
				<br />
				<br />
				Roth Werke GmbH
				<br />
				Am Seerain 1
				<br />
				35232 Dautphetal, Germany
				<br />
				<br />
				Telephone: (+49) 6466/9 22-0
				<br />
				Fax: (+49) 6466/9 22-1 00
				<br />
				<br />
				Website: http://roth-werke.de
				<br />
				E-mail: service@roth-werke.de
				<br />
				<br />
				Managing Directors authorised to represent the company:
				<br />
				Alfred Kajewski, Christin Roth-Jäger
				<br />
				<br />
				Registry court: Amtsgericht Marburg
				<br />
				Registration number: HRB 2726
				<br />
				<br />
				VAT ID no.: DE811225861
				<br />
				<br />
				The software is also supplied by
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				Registration information: Amtsgericht Aachen HRB 5701
				<br />
				VAT ID no.: DE160367433
				<br />
				The company is represented by the Managing Directors: Mr Manfred Waluga, Mr Javier Castell Codesal and Mr Jürgen Frantzen.
				<h3>2. Purpose of the software/exclusion of liability</h3>
				By providing this free software, the suppliers would like to give specialist tradespeople and other interested parties an overview of which materials are needed
				when installing Roth underfloor heating. The software does not replace specialist planning work and equally cannot take into account the individual details involved
				in a building project. Therefore, the information provided automatically by the software must be considered as a guide only and not a mandatory basis for an order.
				The suppliers are therefore also not liable for the accuracy and completeness of the information.
				<h3>3. Personal data</h3>
				This application uses Google Analytics, a web-based analytics services provided by Google Inc. (hereinafter referred to as “Google”). Google Analytics uses
				“cookies”, which are text files that are saved onto your computer and allow your application usage to be analysed. The information generated by cookies about how
				you use this website (including your IP address) is sent to a Google server in the USA where it is then stored. Google will use this information to evaluate your
				usage of the application. This is then used to generate reports for the suppliers about user activity and provide further services connected to the use of the
				application and internet usage. If necessary, Google will also share this information with third parties, provided that it is required by law or the third parties
				are processing the data in question on Google’s behalf. Google will never link your IP address with other data saved on Google’s servers. You can stop cookies from
				being installed on your computer via the settings in your browser software. However, please note that certain application functions cannot be used fully in some
				cases when cookies have been disabled. By accepting the licence agreement, you agree that the information collected on your activity by Google can be processed in
				the manner described above and for the aforementioned purpose.
				<br />
				You are required to enter your full name, address and a valid e-mail address and use a password of your choice in order to use the software. This data is saved
				automatically. It will not be passed onto third parties.
				<br />
				The suppliers reserve the right to use the contact details you provide when sending you important notifications regarding this planning software.
				<h3>4. Creating projects</h3>
				The software enables users to create any number of projects and save them in electronic format. The suppliers can access saved data at any time. Project data is not
				shared with third parties.
				<h3>5. Use of cookies</h3>
				The software uses cookies in order to make using the software an attractive experience and enable certain functions. Cookies are small text files that are stored on
				your device. Some cookies are deleted when a browser session ends, i.e. when you close your browser. Other cookies remain on your device. You can change the
				settings in your browser so that you are notified about the use of cookies and can choose whether to accept them each time. The suppliers cannot guarantee that the
				software will function fully if the use of cookies is not permitted.
				<h3>6. Data protection</h3>
				By registering, you agree to let us collect and save your personal data and project information and use it to improve the services we offer, provide customer
				support, evaluate user activity and contact you in person, if necessary.
				<br />
				You can withdraw your consent for us to use, process and share your personal data at any time by sending us an informal letter to the address in section 1 or by
				e-mailing: service@roth-werke.de. In accordance with the German Federal Data Protection Act (BDSG), you have the right to access your saved data free of charge and
				correct, save or delete it if necessary. After receiving notification that you wish to withdraw your consent, we will no longer use the relevant data. You can also
				delete your account yourself.
				<h3>7. Other information</h3>
				The suppliers reserve the right to shut down the software at any time. In addition, the suppliers cannot guarantee that the information saved to your account will
				be available at all times. In particular, the software cannot be used during maintenance times or malfunctions. A notification e-mail will be sent to users’ saved
				e-mail addresses if the suppliers decide to shut down the software completely and the software will only be deleted four weeks after notification.
				<br />
				Es gilt deutsches Recht.
				<br />
				Stand 06.11.2017
			</Grid>
		),
		roth_nl: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Aanbieder</h3>
				De software wordt gratis beschikbaar gesteld door
				<br />
				<br />
				Roth Werke GmbH
				<br />
				Am Seerain 1
				<br />
				35232 Dautphetal, Germany
				<br />
				<br />
				Telefoon: (+49) 6466/9 22-0
				<br />
				Telefax: (+49) 6466/9 22-1 00
				<br />
				<br />
				Internet: http://roth-werke.de
				<br />
				E-Mail: service@roth-werke.de
				<br />
				<br />
				Beherende vennoten:
				<br />
				Alfred Kajewski, Christin Roth-Jäger
				<br />
				<br />
				Registratiegerecht: Amtsgericht Marburg
				<br />
				Registernummer: HRB 2726
				<br />
				<br />
				Btw-identificatienummer: DE811225861
				<br />
				<br />
				De aanbieder van de software is ook
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				Btw-identificatienummer: DE160367433
				<br />
				De vennootschap wordt vertegenwoordigd door de vennoten: de heren Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen.
				<h3>2. Het doel van de software/ontheffing van de aansprakelijkheid</h3>
				Met dit gratis aanbod willen de aanbieders vaklieden en andere geïnteresseerden een overzicht geven van de materialen die nodig zijn bij de inbouw van een Roth
				vloerverwarming. De software is geen vervanging voor een vakkundige planning. Ook kan er geen rekening worden gehouden met de afzonderlijke bijzonderheden van het
				bouwplan. Daarom gelden de gegevens die de software automatisch berekent alleen als indicatie en niet als een bindende basis voor een bestelling. De aanbieders zijn
				dan ook niet aansprakelijk voor de juistheid en de volledigheid van de gegevens.
				<h3>3. Persoonsgegevens</h3>
				Deze applicatie maakt gebruik van Google-Analytics, een web-analysedienst van Google Inc. ("Google"). Google-Analytics maakt gebruik van zogenaamde "Cookies". Dit
				zijn tekstbestanden die op uw computer worden opgeslagen en die een analyse van uw gebruik van de applicatie mogelijk maken. De informatie over uw gebruik van deze
				website (inclusief uw IP-adres) die door het cookie wordt verzameld, wordt naar een server van Google in den VS verstuurd en daar opgeslagen. Google zal deze
				informatie gebruiken om uw gebruik van de applicatie te beoordelen, om voor de aanbieder rapporten over de uitgevoerde activiteiten op te stellen en om andere
				diensten te verlenen die samenhangen met het gebruik van de applicatie en het internet. Ook zal Google deze informatie eventueel overdragen aan derden, voor zover
				dit wettelijk verplicht is of voor zover derden deze gegevens in opdracht van Google verwerken. Google zal in geen geval uw IP-adres in verband brengen met andere
				gegevens die door Google worden opgeslagen. U kunt de installatie van cookies verhinderen door middel van een instelling in uw browsersoftware. Wij wijzen u er
				echter op dat u in dit geval mogelijk niet alle functies van deze applicatie volledig kunt gebruiken. Door bevestiging van deze licentieovereenkomst verklaart u
				akkoord te gaan met de verwerking van de gegevens die Google over u heeft verzameld op de hierboven beschreven wijze en voor het hierboven vermelde doel.
				<br />
				Voor het gebruik van de software is het bovendien noodzakelijk om o.a. uw volledige naam, adres en een geldig e-mailadres in te voeren, evenals een zelf gekozen
				wachtwoord. Deze gegevens worden automatisch opgeslagen. Ze worden niet doorgegeven aan derden.
				<br />
				De aanbieders behouden zich het recht voor om u te informeren over belangrijke mededelingen in het kader van deze planningssoftware via de opgeslagen
				contactgegevens.
				<h3>4. Aanmaken van projecten</h3>
				Met de software kunnen zoveel projecten worden aangemaakt en virtueel opgeslagen als gewenst. In principe hebben de aanbieders toegang tot de opgeslagen gegevens.
				De projectgegevens worden niet doorgegeven aan derden.
				<h3>5. Gebruik van cookies</h3>
				Om het gebruik van de software op aantrekkelijke wijze vorm te kunnen geven en bepaalde functies mogelijk te maken, wordt gebruik gemaakt van zogenaamde cookies.
				Hierbij gaat het om kleine tekstbestanden die op uw apparaat worden opgeslagen. Deels worden de cookies aan het einde van de browsersessie, dus nadat u uw browser
				heeft afgesloten, weer gewist. Andere cookies blijven bewaard op het door u gebruikte apparaat. U kunt uw browser zo instellen dat u voor elk cookie wordt gevraagd
				of u dit wilt opslaan. De aanbieders kunnen niet garanderen dat de software probleemloos werkt wanneer u het gebruik van cookies niet toestaat.
				<h3>6. Gegevensbeveiliging</h3>
				Door u te registreren stemt u ermee in dat wij uw persoons- en projectgegevens verzamelen, opslaan en gebruiken om onze aanbiedingen en onze klantenservice te
				verbeteren, om uw gebruiksgedrag te beoordelen en eventueel om individueel contact met u op te nemen.
				<br />U kunt op elk moment bezwaar maken tegen het gebruik, de verwerking en verzending van uw persoonsgegevens door ons een informeel bericht te sturen per post
				naar het onder punt 1 genoemde adres of per e-mail naar: service@roth-werke.de. Volgens de Duitse wet inzake bescherming van persoonsgegevens heeft u recht op
				kosteloze informatie over uw opgeslagen gegevens en, indien van toepassing, het recht om uw opgeslagen gegevens te rectificeren, blokkeren of wissen. Na ontvangst
				van uw bezwaar zullen wij de betreffende gegevens niet meer gebruiken. Bovendien heeft u de mogelijkheid om uw account zelf te wissen.
				<h3>7. Overig</h3>
				De aanbieders behouden zich te allen tijde het recht voor om de software buiten gebruik te stellen. Er kan ook geen garantie worden gegeven voor de duurzame
				beschikbaarheid van de gegevens die onder het account zijn opgeslagen. De software kan in het bijzonder bij onderhoudswerkzaamheden of storingen niet worden
				gebruikt. Mochten de aanbieders besluiten om de software geheel buiten gebruik te stellen, dan wordt er een kennisgeving gestuurd naar de opgeslagen e-mailadressen.
				De software wordt niet binnen een termijn van vier weken gewist.
				<br />
				German law shall apply.
				<br />
				Stand 06.11.2017
			</Grid>
		),
		schuetz_de: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos von der
				<br />
				<br />
				Schütz GmbH & Co. KGaA
				<br />
				Schützstraße 12
				<br />
				D-56242 Selters
				<br />
				Sitz: Selters
				<br />
				<br />
				Handelsregister: Amtsgericht Montabaur HRB 6405
				<br />
				Umsatzsteuer-ID-Nr.: DE 207 990 046
				<br />
				<br />
				Tel.: +49 (0) 26 26 / 77 - 0
				<br />
				Fax: +49 (0) 26 26 / 77 - 5 32
				<br />
				<br />
				Persönlich haftende Gesellschafterin:
				<br />
				Schütz-Werke GmbH & Co. KG, Selters/Westerwald
				<br />
				AG Montabaur HRA 1292
				<br />
				diese vertreten durch
				<br />
				Schütz Verwaltungs GmbH, Selters/Westerwald
				<br />
				Amtsgericht Montabaur HRB 2003
				<br />
				<br />
				vertreten durch die Geschäftsführung der persönlich haftenden Gesellschafterin:
				<br />
				Udo Schütz, Veit Enders, Holger Gelhard,
				<br />
				Winfried Heibel, Dr. René Schmitz, Roland Strassburger
				<br />
				<br />
				zur Verfügung gestellt.
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<br />
				<h3> 2. Zweck der Software</h3>
				Mit diesem kostenlosen Angebot wollen die Anbieter für Fachhandwerker und andere Interessierte einen Überblick verschaffen, welche Materialien bei dem Einbau einer
				Schütz Fußbodenheizung benötigt werden. Die Software ersetzt keine Fachplanung. Sie kann auch individuelle Besonderheiten eines Bauvorhabens nicht berücksichtigen.
				Daher sind die Daten, die durch die Software automatisch ermittelt werden, nur als Anhaltspunkt, nicht aber als eine verbindliche Grundlage für eine Bestellung zu
				sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<h3> 3. Personenbezogene Daten</h3>
				Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. („Google“). Google-Analytics verwendet sog. „Cookies“ Textdateien, die auf Ihrem
				Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre
				Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese
				Informationen benutzen, um Ihre Nutzung der Applikation auszuwerten, um Reports über die stattgefundenen Aktivitäten für die Anbieter zusammen zu stellen und um
				weitere mit der Nutzung der Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte
				übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
				anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer
				Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht sämtliche Funktionen dieser Applikation vollumfänglich genutzt werden
				können. Durch die Bestätigung dieser Lizenzvereinbarung erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschrieben Art
				und Weise und zu dem zuvor benannten Zweck einverstanden.Zur Benutzung der Software ist es zudem erforderlich, eine gültige Email-Adresse einzugeben und ein
				selbstgewähltes Passwort zu verwenden. Diese Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten
				außerhalb der Software findet nicht statt. Die Anbieter werden die hinterlegten Email-Adressen auch nicht für Werbezwecke verwenden. Allerdings behalten es sich die
				Anbieter vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei Änderung der Benutzerbedingungen, Programmerweiterungen oder bei
				Abschalten der Software hierüber über die hinterlegte Email-Adresse zu informieren.
				<h3> 4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt werden können. Diese können virtuell abgespeichert werden. Grundsätzlich besteht für die Anbieter die
				Möglichkeit zum Zugriff auf die hinterlegten Daten. Die Anbieter werden hiervon keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht für eigene Zwecke
				auswerten.
				<h3> 5. Einsatz von Cookies</h3>
				Um die Nutzung der Software attraktiv gestalten zu können und bestimmte Funktionen zu ermöglichen, verwendet die Software sog. Cookies. Hierbei handelt es sich um
				kleine Textdateien, die auf ihrem Endgerät abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers wieder
				gelöscht. Andere Cookies verbleiben auf Ihrem Endgerät. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und über
				deren Annahme im Einzelnen entscheiden. Die Anbieter können nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht
				gestattet wird.
				<h3> 6. Datenschutz</h3>
				%a(href="//www.schuetz.net/de/datenschutz/" target="blank") Schütz Datenschutz
				<h3> 7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht. Es gilt deutsches Recht.
				<br />
				Stand 25.05.2018
			</Grid>
		),
		somatherm_fr: (
			<Grid container direction="column" sx={GridPadding}>
				Somatherm S.A.S
				<br />
				<br />
				13, place Francheville
				<br />
				BP 202 – 24052 Périgueux
				<br />
				CT Cedex 9 (France)
				<br />
				<br />
				Tél : 05 53 02 69 70
				<br />
				Fax : 05 53 03 94 48
				<br />
				E-mail : contact@somatherm.fr
				<br />
				<br />
				Siret : 68198003300010
				<br />
				Responsable : M. HAMMEL Raymond
				<br />
				<br />
				Application web développée et fournie par
				<br />
				LINEAR GmbH
				<br />
				Im Suesterfeld 20
				<br />
				52072 Aachen
				<br />
				ALLEMAGNE
				<br />
				Registre du commerce : Amtsgericht Aachen HRB 5701 HRB 5701
				<br />
				N° d'identification TVA : DE16036747433.
				<br />
				Conseil d'administration : Manfred Waluga, Javier Castell Codesal et Juergen Frantzen
				<br />
				<br />
				<br />
				<h3> 2. Purpose of the software </h3>
				With this free offer, the suppliers want to give specialist craftsmen and other interested parties the opportunity to get an overview of which materials are
				required for the installation of a Henco Floor underfloor heating system. The software does not contain any specialist planning. It cannot take individual features
				of a building project into consideration. For this reason, the data which is automatically detected by the software can only serve as an indication rather than a
				binding basis for an order. The supplier is therefore not liable for the accuracy and completeness of the data.
				<h3> 3. Personal data </h3>
				This application uses Google-Analytics, a web analysis service from Google Inc. ("Google"). Google-Analytics uses so-called "cookies": text files which are saved on
				your computer and enables analysis of your use of the application. The information created by the cookie about your use of the website (including your IP address)
				is transmitted to a Google server in the USA and saved there. Google will use this information to evaluate your use of the application, to compile reports about
				your activities for the suppliers and to provide further services related to use of the application and the internet. Google may also transmit this information to
				third parties in so far as this is legally required or in so far as third parties process this data on behalf of Google. Google will never link your IP address with
				other data saved by Google. You can prevent installation of cookies using corresponding browser software settings. We would like to point out however, that in this
				case not all the functions of this application will be able to be used to their full extent. Through confirmation of this licence agreement you declare your
				agreement with the processing of the data collected about you by Google in the way described above and for the above-mentioned purpose. In order to use the software
				it is necessary to enter a valid email address and a password of your choice. This data is automatically saved and will not be forwarded to third parties. The
				suppliers will not use the stored email addresses for advertising purposes. However, the suppliers reserve the right to use the stored email addresses to
				communicate important information related to this planning software, such as changes to the conditions of use, extensions to the program or software shutdown.
				<h3> 4. Creation of projects </h3>
				The software enables any number of projects to be created. These can be saved in a virtual environment. The suppliers have no storage or archiving obligations. For
				this reason, users should save the project data in question themselves. It is possible in principle for the suppliers to access the stored data. Project data will
				not be forwarded to third parties. However, the suppliers reserve the right to query the necessity and suitability of the data use. This gives the suppliers the
				possibility of preparing necessary evaluations and statistics.
				<h3> 5. Use of cookies </h3>
				The software uses so-called cookies to be able to make use of the software attractive and permit specific functions. These are small text files which are saved on
				your terminal. Some of the cookies are deleted at the end of the browser session, i.e. once you close your browser. Other cookies remain on your terminal. You can
				adjust your browser settings in such a way that you are informed about cookies being set and can decide individually about whether to accept their use. The
				suppliers cannot guarantee that the software will work perfectly if the use of cookies is not permitted.
				<h3> 6. Data protection </h3>
				You can object to the use, processing and forwarding of your personal data at any time by sending an informal letter by post to the address stipulated in section 1
				of these conditions or by sending an email to the named address of the provider. In accordance with the BDSG (Federal Data Protection Act)” you have a right to free
				access to information about the personal data saved about you as well as possibly a right to correction, disabling or deletion of this data. We will no longer use
				the data concerned upon receipt of your objection. In addition, you have the possibility of deleting your account yourself.
				<h3> 7. Miscellaneous </h3>
				The suppliers retain the right to shut down the software at any time. No guarantee can be given for the permanent availability of the data saved on the accounts.
				The software cannot be used in particular during maintenance work or disruptions. If the suppliers should decide to shut down the software completely, an email
				message will be sent to the stored email addresses. The software will not be deleted before a period of 4 weeks has passed.
				<br />
				France law shall apply. 1. July 2018
			</Grid>
		),
		viega_de: (
			<Grid container direction="column" sx={GridPadding}>
				<h2> Benutzerbedingungen </h2>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos von der
				<br />
				Viega Holding GmbH & Co. KG
				<br />
				Viega Platz 1
				<br />
				57439 Attendorn
				<br />
				Sitz: Attendorn
				<br />
				Handelsregister: Amtsgericht Siegen HRA 7404
				<br />
				Umsatzsteuer-ID-Nr.: DE126180387
				<br />
				Komplementärinnen: Viega Holding Beteiligungs B.V. (Vorsitzende der Geschäftsführung: Walter Viegener, Claus Holst-Gydesen; Geschäftsführer: Ralf Baginski, Andreas
				Brockow, Andreas Fiefhaus, Dirk Gellisch, Peter Schöler); Viega Holding Beteiligungs GmbH (Geschäftsführer: Walter Viegener, Claus Holst-Gydesen)
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<h3> 2. Zweck der Software</h3>
				Mit diesem kostenlosen Angebot wollen die Anbieter für Fachhandwerker und andere Interessierte einen Überblick verschaffen, welche Materialien bei dem Einbau einer
				Fonterra Fußbodenheizung benötigt werden. Die Software ersetzt keine Fachplanung. Sie kann auch individuelle Besonderheiten eines Bauvorhabens nicht
				berücksichtigen. Daher sind die Daten, die durch die Software automatisch ermittelt werden, nur als Anhaltspunkt, nicht aber als eine verbindliche Grundlage für
				eine Bestellung zu sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<h3> 3. Personenbezogene Daten</h3>
				Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. („Google“). Google-Analytics verwendet sog. „Cookies“ Textdateien, die auf Ihrem
				Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre
				Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese
				Informationen benutzen, um Ihre Nutzung der Applikation auszuwerten, um Reports über die stattgefundenen Aktivitäten für die Anbieter zusammen zu stellen und um
				weitere mit der Nutzung der Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte
				übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
				anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer
				Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht sämtliche Funktionen dieser Applikation vollumfänglich genutzt werden
				können. Durch die Bestätigung dieser Lizenzvereinbarung erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschrieben Art
				und Weise und zu dem zuvor benannten Zweck einverstanden.Zur Benutzung der Software ist es zudem erforderlich, eine gültige Email-Adresse einzugeben und ein
				selbstgewähltes Passwort zu verwenden. Diese Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten
				außerhalb der Software findet nicht statt. Die Anbieter werden die hinterlegten Email-Adressen auch nicht für Werbezwecke verwenden. Allerdings behalten es sich die
				Anbieter vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei Änderung der Benutzerbedingungen, Programmerweiterungen oder bei
				Abschalten der Software hierüber über die hinterlegte Email-Adresse zu informieren.
				<h3> 4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt werden können. Diese können virtuell abgespeichert werden. Grundsätzlich besteht für die Anbieter die
				Möglichkeit zum Zugriff auf die hinterlegten Daten. Die Anbieter werden hiervon keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht für eigene Zwecke
				auswerten.
				<h3> 5. Einsatz von Cookies</h3>
				Um die Nutzung der Software attraktiv gestalten zu können und bestimmte Funktionen zu ermöglichen, verwendet die Software sog. Cookies. Hierbei handelt es sich um
				kleine Textdateien, die auf ihrem Endgerät abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers wieder
				gelöscht. Andere Cookies verbleiben auf Ihrem Endgerät. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und über
				deren Annahme im Einzelnen entscheiden. Die Anbieter können nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht
				gestattet wird.
				<h3> 6. Datenschutz</h3>
				Sie können der Nutzung, Verarbeitung und Übermittlung Ihrer personenbezogenen Daten jederzeit durch eine formlose Mitteilung auf dem Postweg unter der in Ziffer 1
				genannten Adresse oder durch eine Email an:service-software@viega.de widersprechen. Nach BDSG haben Sie ein Recht auf unentgeltliche Auskunft über Ihre
				gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Nach Erhalt Ihres Widerspruchs, werden wir die betroffenen Daten
				nicht mehr verwenden. Außerdem besteht die Möglichkeit Ihren Account selbst zu löschen. %a(href="//www.viega.de/datenschutz" target="blank") Viega Datenschutz
				<h3> 7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht. Es gilt deutsches Recht.
				<br />
				Stand 25.05.2018
			</Grid>
		),
		viessmann_de: (
			<Grid container direction="column" sx={GridPadding}>
				<h3> 1. Anbieter</h3>
				Die Software wird kostenlos von der
				<br />
				  Viega Holding GmbH & Co. KG
				<br />
				Viega Platz 1
				<br />
				57439 Attendorn
				<br />
				Sitz: Attendorn
				<br />
				Handelsregister: Amtsgericht Siegen HRA 7404
				<br />
				Umsatzsteuer-ID-Nr.: DE126180387
				<br />
				Komplementärinnen: Viega Holding Beteiligungs B.V. (Vorsitzende der Geschäftsführung: Walter Viegener, Claus Holst-Gydesen; Geschäftsführer: Ralf Baginski, Andreas
				Brockow, Andreas Fiefhaus, Dirk Gellisch, Peter Schöler); Viega Holding Beteiligungs GmbH (Geschäftsführer: Walter Viegener, Claus Holst-Gydesen)
				<br />
				<br />
				Anbieter der Software ist auch die
				<br />
				LINEAR Gesellschaft für konstruktives Design mbH
				<br />
				Im Süsterfeld 20
				<br />
				52072 Aachen
				<br />
				HR: Amtsgericht Aachen HRB 5701
				<br />
				USt-ID-Nr.: DE160367433.
				<br />
				Die Gesellschaft wird durch Ihre Geschäftsführer, Herrn Manfred Waluga, Javier Castell Codesal, Jürgen Frantzen, vertreten.
				<h3> 2. Zweck der Software</h3>
				Mit diesem kostenlosen Angebot wollen die Anbieter für Fachhandwerker und andere Interessierte einen Überblick verschaffen, welche Materialien bei dem Einbau einer
				Fonterra Fußbodenheizung benötigt werden. Die Software ersetzt keine Fachplanung. Sie kann auch individuelle Besonderheiten eines Bauvorhabens nicht
				berücksichtigen. Daher sind die Daten, die durch die Software automatisch ermittelt werden, nur als Anhaltspunkt, nicht aber als eine verbindliche Grundlage für
				eine Bestellung zu sehen. Die Anbieter haften daher auch nicht für die Richtigkeit und Vollständigkeit der Daten.
				<h3> 3. Personenbezogene Daten</h3>
				Diese Applikation benutzt Google-Analytics, einen Web-Analysedienst der Google Inc. („Google“). Google-Analytics verwendet sog. „Cookies“ Textdateien, die auf Ihrem
				Computer gespeichert werden und die eine Analyse der Benutzung der Applikation durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen über Ihre
				Benutzung dieser Website (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese
				Informationen benutzen, um Ihre Nutzung der Applikation auszuwerten, um Reports über die stattgefundenen Aktivitäten für die Anbieter zusammen zu stellen und um
				weitere mit der Nutzung der Applikation und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen ggf. an Dritte
				übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit
				anderen Daten, die von Google gespeichert werden, in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer
				Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass in diesem Fall ggf. nicht sämtliche Funktionen dieser Applikation vollumfänglich genutzt werden
				können. Durch die Bestätigung dieser Lizenzvereinbarung erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschrieben Art
				und Weise und zu dem zuvor benannten Zweck einverstanden.Zur Benutzung der Software ist es zudem erforderlich, eine gültige Email-Adresse einzugeben und ein
				selbstgewähltes Passwort zu verwenden. Diese Daten werden automatisch gespeichert. Sie werden nicht an Dritte weitergegeben. Eine Weiterverarbeitung der Daten
				außerhalb der Software findet nicht statt. Die Anbieter werden die hinterlegten Email-Adressen auch nicht für Werbezwecke verwenden. Allerdings behalten es sich die
				Anbieter vor, bei wichtigen Mitteilungen, die diese Planungssoftware betreffen, beispielsweise bei Änderung der Benutzerbedingungen, Programmerweiterungen oder bei
				Abschalten der Software hierüber über die hinterlegte Email-Adresse zu informieren.
				<h3> 4. Anlegen von Projekten</h3>
				Die Software ermöglicht, dass beliebig viele Projekte angelegt werden können. Diese können virtuell abgespeichert werden. Grundsätzlich besteht für die Anbieter die
				Möglichkeit zum Zugriff auf die hinterlegten Daten. Die Anbieter werden hiervon keinen Gebrauch machen. Insbesondere werden sie diese Daten nicht für eigene Zwecke
				auswerten.
				<h3> 5. Einsatz von Cookies</h3>
				Um die Nutzung der Software attraktiv gestalten zu können und bestimmte Funktionen zu ermöglichen, verwendet die Software sog. Cookies. Hierbei handelt es sich um
				kleine Textdateien, die auf ihrem Endgerät abgelegt werden. Teilweise werden Cookies nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers wieder
				gelöscht. Andere Cookies verbleiben auf Ihrem Endgerät. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und über
				deren Annahme im Einzelnen entscheiden. Die Anbieter können nicht garantieren, dass die Software einwandfrei funktioniert, wenn die Verwendung von Cookies nicht
				gestattet wird.
				<h3> 6. Datenschutz</h3>
				Sie können der Nutzung, Verarbeitung und Übermittlung Ihrer personenbezogenen Daten jederzeit durch eine formlose Mitteilung auf dem Postweg unter der in Ziffer 1
				genannten Adresse oder durch eine Email an:service-software@viega.de widersprechen. Nach BDSG haben Sie ein Recht auf unentgeltliche Auskunft über Ihre
				gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Nach Erhalt Ihres Widerspruchs, werden wir die betroffenen Daten
				nicht mehr verwenden. Außerdem besteht die Möglichkeit Ihren Account selbst zu löschen. %a(href="//www.viega.de/datenschutz" target="blank") Viega Datenschutz
				<h3> 7. Sonstiges</h3>
				Die Anbieter behalten sich jederzeit vor, die Software abzuschalten. Es kann auch keine Garantie für die dauerhafte Verfügbarkeit der unter den Accounts
				hinterlegten Daten abgegeben werden. Die Software kann insbesondere bei Wartungsarbeiten oder Störungen nicht verwendet werden. Sollten die Anbieter sich
				entscheiden, die Software vollständig abzuschalten, wird an die hinterlegten Email-Adressen eine Benachrichtigungs-Email versandt. Die Software wird nicht vor
				Ablauf von 4 Wochen gelöscht. Es gilt deutsches Recht.
				<br />
				Stand 25.05.2018
			</Grid>
		),
	};

	return (
		<Layout>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" sx={GridPadding}>
					<Typography component="h4" variant="h4">
						{_t("Benutzerbedingungen")}
					</Typography>
				</Grid>

				{vendorList[props.vendor.postfix === "henco_nl" ? props.vendor.postfix : props.vendor.postfix === "v&n" ? "vun_de" : props.vendor.postfix + "_" + lang]}
			</Grid>
		</Layout>
	);
}

export default TermsOfUse;
