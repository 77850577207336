import React from "react";
import { Button, Grid, Modal } from "@mui/material";

import { VendorStylesContainer } from "../../../mainStyle.js";

import { _t } from "../../../store/translate.js";
import { ModalDialogStyles } from "./modalStyle.js";

export function ModalDialog(props) {
	let [open, setOpen] = React.useState(props.opened);

	// const handleOpen = () => {
	// 	setOpen(true);
	// };

	const handleClose = () => {
		if (props.closed) {
			props.closed(false);
		}
		setOpen(false);
	};

	const handleAcceptbutton = () => {
		handleClose();
		props.acceptbutton();
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			component={VendorStylesContainer}
			vendor={props.vendor.postfix}
		>
			<ModalDialogStyles>
				{props.title && <h2 id="simple-modal-title">{props.title}</h2>}
				{props.text && <p id="simple-modal-description">{props.text}</p>}
				{props.children}
				{props.acceptbutton && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="contained" color="primary" onClick={handleAcceptbutton}>
								{props.primaryButtonTitle ? _t(props.primaryButtonTitle) : _t("OK")}
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="inherit" onClick={handleClose}>
								{props.cancelButtonTitle ? _t(props.cancelButtonTitle) : _t("Abbrechen")}
							</Button>
						</Grid>
					</Grid>
				)}
				{!props.acceptbutton && !props.navigateTo && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Button variant="contained" color="primary" onClick={handleClose}>
							{_t("OK")}
						</Button>
					</Grid>
				)}
				{props.navigateTo && (
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="contained" color="primary" onClick={handleClose} href={props.navigateTo} target="_blank" rel="noopener noreferrer">
								{_t("Anzeigen")}
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="inherit" onClick={handleClose}>
								{_t("Abbrechen")}
							</Button>
						</Grid>
					</Grid>
				)}
			</ModalDialogStyles>
		</Modal>
	);
}
